<script>
export default {
  props: {
    visible: Boolean,
    cardArrangements: Object,
    fieldKeyOptions: Array
  },

  data: () => ({
    arrangement: {
      orderBy: null,
      descendingOrder: false,
      groupBy: null,
      collapsedGroups: []
    }
  }),

  watch: {
    visible: {
      immediate: true,
      handler (value) {
        if (!value) {
          return
        }

        this.arrangement = this.cardArrangements
      }
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    apply () {
      this.$emit('apply', this.arrangement)
      this.$emit('close')
    }
  }
}
</script>

<template>
  <BaseDialog
    :visible="visible"
    :cancelOptions="{ label: 'Close' }"
    @close="close"
  >
    <h3>Arrange Cards</h3>

    <div class="form">
      <label>
        <span class="title">Group By Field</span>
        <select v-model="arrangement.groupBy">
          <option :value="null">-</option>
          <option
            v-for="option of fieldKeyOptions"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </select>
      </label>

      <label>
        <span class="title">Order By Field</span>
        <select v-model="arrangement.orderBy">
          <option :value="null">-</option>
          <option
            v-for="option of fieldKeyOptions"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </select>
      </label>

      <label>
        <input v-model="arrangement.descendingOrder" type="checkbox" />
        <span class="title">Descending Order</span>
      </label>
    </div>

    <div slot="buttons">
      <button class="button full primary" @click="apply">
        Apply
      </button>
    </div>
  </BaseDialog>
</template>
