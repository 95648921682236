import Vue from 'vue'
import VueRouter from 'vue-router'

import Start from '@/views/start'
import Databases from '@/views/databases'
import Login from '@/views/login'
import ListIndex from '@/views/list-index'
import ListConfigEdit from '@/views/list-config-edit'
import ListConfigCreate from '@/views/list-config-create'
import CardDetail from '@/views/card-detail'
import CardAdd from '@/views/card-add'
import UserGuide from '@/views/user-guide'
import RemovedLists from '@/views/removed-lists'
import ImportExport from '@/views/import-export'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'start',
    component: Start
  },

  {
    path: '/databases',
    name: 'databases',
    component: Databases
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/list/create',
    name: 'listCreate',
    component: ListConfigCreate
  },

  {
    path: '/list/:listId/config',
    name: 'listConfig',
    component: ListConfigEdit
  },

  {
    path: '/list/:listId',
    name: 'listIndex',
    component: ListIndex,
    props: true
  },

  {
    path: '/list/:listId/add',
    name: 'cardAdd',
    component: CardAdd
  },

  {
    path: '/list/:listId/card/:cardId',
    name: 'cardDetail',
    component: CardDetail
  },

  {
    path: '/user-guide',
    name: 'userGuide',
    component: UserGuide
  },

  {
    path: '/removed-lists',
    name: 'removedLists',
    component: RemovedLists
  },

  {
    path: '/import-export',
    name: 'importExport',
    component: ImportExport
  }
]

const router = new VueRouter({
  routes
})

export default router
