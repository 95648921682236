function evaluatorSetup () {
  if (window.document.getElementById(IFRAME_ID) != null) {
    return
  }

  const iframe = window.document.createElement('iframe')
  iframe.id = IFRAME_ID
  iframe.style.display = 'none'
  iframe.setAttribute('sandbox', 'allow-scripts')

  iframe.setAttribute('srcdoc', `<html><head><script>
    window.addEventListener('load', event => {
      window.parent.postMessage({
        eventId: 'setupDone',
        result: undefined,
        source: 'evaluator-iframe',
      }, '*');
    })

    window.addEventListener('message', function (event) {
      const post = result => event.source.postMessage({
        ...result,
        eventId,
        source: 'evaluator-iframe'
      }, '*');
      const eventId = event.data.eventId;

      if (!eventId) {
        console.error(
          'Evaluator: Tried to evaluate without eventId (%s)',
          JSON.stringify(event.data)
        );
      }

      const evilFunction = [
        '(function (args) {',
        'let post, eventId, event = null;',
        event.data.code,
        '})(event.data.payload)'
      ].join('\\n');

      try {
        const result = eval(evilFunction);
        post({ result: JSON.stringify(result) });
      } catch (error) {
        const location = error.lineNumber + ':' + error.columnNumber
        post({ error: {
          displayMessage: error.message + ' ' + location,
          message: error.message,
          location
        } });
      }
    });
  </script></head>`)

  window.document.body.appendChild(iframe)

  return new Promise((resolve, reject) => {
    promisesMap.setupDone = { resolve, reject }
  })
}

function onMessage (event) {
  const eventId = event.data.eventId

  if (event.data.source !== 'evaluator-iframe' || eventId == null) {
    return
  }

  const promise = promisesMap[eventId]

  if (promise == null) {
    console.error('Evaluator: execution with id %s has no pending promise', eventId)
    return
  }

  if (({}).hasOwnProperty.call(event.data, 'error')) {
    promise.reject(event.data.error)
    return
  }

  delete promisesMap[eventId]

  if (!({}).hasOwnProperty.call(event.data, 'result')) {
    return
  }

  if (event.data.result === undefined) {
    promise.resolve()
    return
  }

  promise.resolve(JSON.parse(event.data.result))
}

function evaluator (code, payload) {
  const eventId = (Math.random() + Object.keys(promisesMap).length).toString(36)
  const iframe = window.document.getElementById(IFRAME_ID)

  if (iframe == null) {
    console.error('Evaluator: iframe with id "%s" is missing', IFRAME_ID)
    return
  }

  return new Promise((resolve, reject) => {
    promisesMap[eventId] = { resolve, reject }
    iframe.contentWindow.postMessage({ code, payload, eventId }, '*')
  })
}

const IFRAME_ID = 'evaluator-iframe'
const promisesMap = {}

window.addEventListener('message', onMessage)

export { evaluatorSetup, evaluator }
