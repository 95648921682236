export const LIST_FLAGS = {
  /*
   * Hides the list on the start view
   */
  HIDDEN: 'HIDDEN',

  /*
   * Renders a preview of all (unchecked) cards on the start view
   */
  WRAPABLE: 'WRAPABLE',

  /*
   * Prevents uploading to server and deletion when the list was not found on
   * the server. If the list is already uploaded it will not remove or update it.
   * TODO: Is not implemented anymore
   */
  NO_REMOTE: 'NO_REMOTE',

  /*
   * Hidden flags starting with an underscore: Can be added by the user
   * manually, but are not presented as available options
   * when opening the flags multiselect.
   *
   * Disables the save action of a list config
   */
  _NO_CONFIG_SAVE: '_NO_CONFIG_SAVE',

  /*
   * Disables the delete action of a list config
   */
  _NO_CONFIG_DELETE: '_NO_CONFIG_DELETE',

  /*
   * Config will never be written to storage
   * TODO: Is not implemented anymore
   */
  _NO_CONFIG_STORAGE: '_NO_CONFIG_STORAGE',

  /*
   * Cards will never be written to storage
   * TODO: Is not implemented anymore
   */
  _NO_CARDS_STORAGE: '_NO_CARDS_STORAGE',

  /*
   * Disables the card save action of a list
   */
  _NO_CARDS_SAVE: '_NO_CARDS_SAVE',

  /*
   * Disable the card delete action of a list
   */
  _NO_CARDS_DELETE: '_NO_CARDS_DELETE',

  /*
   * Disables the card creation action of a list
   */
  _NO_CARDS_ADD: '_NO_CARDS_ADD'
}
