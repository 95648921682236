<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AboutApp',

  props: {
    dialogId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isChecking: 'serviceWorker/isChecking',
      hasWaiting: 'serviceWorker/hasWaiting',
      isUpdating: 'serviceWorker/isUpdating',
      lastUpdate: 'serviceWorker/lastUpdate'
    }),

    logoUrl () {
      return process.env.BASE_URL + 'icons/android-chrome-192x192.png'
    }
  },

  methods: mapMutations({
    checkForUpdate: 'serviceWorker/checkForUpdate',
    unsetChecking: 'serviceWorker/unsetChecking',
    update: 'serviceWorker/update'
  })
}
</script>

<template>
  <BaseGlobalDialog :dialog-id="dialogId" @close="unsetChecking">
    <div style="text-align: center">
      <img :src="logoUrl" />
    </div>

    <p class="small" style="text-align: center">Logo by merchen</p>

    <h3>Brokkoli Report</h3>

    <p>
      Version: {{ lastUpdate.version }}<br />
      Build Date: {{ lastUpdate.buildDate }}
    </p>

    <div slot="buttons">
      <button v-if="isChecking" class="button full disabled">
        <BaseSpinner />
      </button>

      <button v-else-if="hasWaiting" class="button full" @click="update">
        Update App
      </button>

      <button v-else class="button full" @click="checkForUpdate">
        Check for Update
      </button>

      <div style="height:20px"></div>
    </div>
  </BaseGlobalDialog>
</template>
