import Vue from 'vue'

import './register-service-worker'
import './base-components'
import router from './router'
import store from './store'
import helper from './helper'

import App from './app.vue'

Vue.config.productionTip = false

Vue.prototype.$helper = helper

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
