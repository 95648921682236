import lm from 'legible-mergeable'
import { openDB, deleteDB } from 'idb'
import helper from '../helper'
import * as localStorage from './local-storage'

export let db = null

const DATABASE_KEY_PREFIX = process.env.VUE_APP_NAME + '.'

export function isSomeDatabaseOpen () {
  return db != null
}

export function lastOpenedDatabase () {
  const databases = localStorage.all({ key: 'databases' })
  const lastOpenedDatabase = localStorage.get({ key: 'lastOpenedDatabase' })

  return databases.find(database => database.id === lastOpenedDatabase?.id)
}

function writeLastOpenedDatabase (database) {
  localStorage.set({ key: 'lastOpenedDatabase', value: database })
}

export function availableDatabases () {
  return localStorage.all({ key: 'databases' })
}

export function create ({ label }) {
  localStorage.push({
    key: 'databases',
    item: { label, id: DATABASE_KEY_PREFIX + helper.generateRandomId() }
  })
}

export async function getConnectionData (store) {
  const connection = {
    baseUrl: await store.get('baseUrl'),
    username: await store.get('username'),
    password: await store.get('password')
  }

  if (!connection.baseUrl || !connection.username || !connection.password) {
    throw new Error('You need to login on your server.')
  }

  return connection
}

export async function openAndMigrateDatabase (databaseItem) {
  db = await openDB(databaseItem?.id || process.env.VUE_APP_NAME, 1, {
    upgrade (db) {
      db.createObjectStore('index')
      db.createObjectStore('config', { keyPath: 'id' })
      db.createObjectStore('cards')

      db.createObjectStore('connection')
      db.createObjectStore('queue', { keyPath: ['storeName', 'key'] })
    }
  })

  const tx = db.transaction('index', 'readwrite')

  if (!await tx.store.get('list')) {
    await tx.store.put(lm.touch({}), 'list')
  }

  writeLastOpenedDatabase(databaseItem)
}

export async function deleteDatabase (databaseItem) {
  const databases = localStorage.all({ key: 'databases' })
    .filter(database => database.id !== databaseItem.id)

  localStorage.set({ key: 'databases', value: databases })

  writeLastOpenedDatabase(null)

  await deleteDB(databaseItem.id)
}

export async function closeCurrentDatabase () {
  if (db != null) {
    db.close()
    db = null
  }

  writeLastOpenedDatabase(null)
}
