import lm from 'legible-mergeable'
import helper from '@/helper'
import * as webdavApi from './webdav-api'
import { db, getConnectionData } from './database'

const TASK_METHOD = {
  WRITE: 'WRITE',
  DELETE: 'DELETE'
}

export const HTTP_STATUS = {
  OK: 200,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404
}

export async function authenticate (url, username, password) {
  const connection = await getConnectionData(db.transaction('connection').store)
    .catch(error => error)

  connection.baseUrl = url || connection.baseUrl
  connection.username = username || connection.username
  connection.password = password || connection.password

  const response = await webdavApi.get('index', 'list', connection)
  const store = db.transaction('connection', 'readwrite').store

  await store.put(connection.baseUrl, 'baseUrl')
  await store.put(connection.username, 'username')
  await store.put(connection.password, 'password')

  return response
}

export async function queueWriteToRemote (tx, type, listname) {
  await tx.objectStore('queue').put({
    storeName: type,
    key: listname,
    method: TASK_METHOD.WRITE
  })
}

export async function queueDeleteOnRemote (tx, type, listname) {
  await tx.objectStore('queue').put({
    storeName: type,
    key: listname,
    method: TASK_METHOD.DELETE
  })
}

export async function fetchAll () {
  const tx = db.transaction(['connection', 'queue'])

  if (await tx.objectStore('queue').count() > 0) {
    throw new Error('Fetch failed. The queue needs to be run first.')
  }

  const connection = await getConnectionData(tx.objectStore('connection'))
  const uploadIfNotFound = true

  const index = await syncFile('index', 'list', connection, { uploadIfNotFound })
  const promises = []

  for (const listId in lm.base(index)) {
    promises.push(await syncFile('config', listId, connection, { uploadIfNotFound }))
    promises.push(await syncFile('cards', listId, connection, { uploadIfNotFound }))
  }

  await Promise.all(promises)
}

export function fetchList (listId) {
  return Promise.allSettled([
    fetchFile('config', listId),
    fetchFile('cards', listId)
  ])
}

export async function runQueue () {
  console.log('sync/runQueue...')
  const promises = []

  const tx = db.transaction(['queue', 'connection'], 'readwrite')
  const connection = await getConnectionData(tx.objectStore('connection'))
  let cursor = await tx.objectStore('queue').openCursor()

  const successfulQueueTasks = []

  while (cursor) {
    let currentPromise
    const task = cursor.value

    if (task.method === TASK_METHOD.WRITE) {
      currentPromise = syncFile(task.storeName, task.key, connection, { uploadIfNotFound: true })
    }

    if (task.method === TASK_METHOD.DELETE) {
      currentPromise = deleteFile(task.storeName, task.key, connection)
    }

    currentPromise.then(() => successfulQueueTasks.push([task.storeName, task.key]))

    promises.push(currentPromise)

    cursor = await cursor.continue()
  }

  await tx.done

  return Promise.allSettled(promises)
    .then(result => {
      const store = db.transaction('queue', 'readwrite').store

      for (const key of successfulQueueTasks) {
        store.delete(key)
      }

      return result
    })
}

export async function fetchFile (type, listname) {
  log('fetchFile', type, listname)
  const tx = db.transaction(['connection', 'queue'])

  if (await tx.objectStore('queue').get([type, listname])) {
    throw new Error(`Fetch for ${type}.${listname} failed. The queue needs to be run first.`)
  }

  const connection = await getConnectionData(tx.objectStore('connection'))

  return syncFile(type, listname, connection, { uploadIfNotFound: false })
}

export async function deleteFile (type, listname, connection) {
  log('deleteFile', type, listname)
  await webdavApi.remove(type, listname, connection)
}

export async function syncFile (type, listname, connection, { uploadIfNotFound }) {
  log('syncFile', type, listname, { uploadIfNotFound })

  const { response, body: responseData } = await webdavApi.get(type, listname, connection)
  const status = response.status

  if (!uploadIfNotFound && status === HTTP_STATUS.NOT_FOUND) {
    throw new Error(`Fetch for ${type}.${listname} failed. File not found on server. (Response Status Code: 404)`)
  }

  const domainStore = db.transaction(type, 'readwrite').store
  let property = await domainStore.get(listname)
  let changed = false

  const existsLocally = helper.isObject(property)

  log('syncFile/response', type, listname, { status, existsLocally })

  if (status === HTTP_STATUS.OK && existsLocally) {
    try {
      property = lm.mergeOrFail(responseData, property)
      changed = true
    } catch (error) {
      if (!(error instanceof lm.MergeResultIdenticalError)) {
        throw error
      }
    }
  }

  // TODO: shouldn't this also check for STATUS.OK?
  if (!existsLocally) {
    property = responseData
  }

  if (changed || !existsLocally) {
    if (domainStore.keyPath == null) {
      await domainStore.put(property, listname)
    } else {
      await domainStore.put(property)
    }
  }

  if (changed || (uploadIfNotFound && status === HTTP_STATUS.NOT_FOUND)) {
    log('syncFile/upload', type, listname, { changed })
    await webdavApi.put(type, listname, property, connection)
  }

  return property
}

function log (location, type, listname, message) {
  console.log('database/' + location, type + '.' + listname, message || '')
}
