export function orderCards (arrangement, cards) {
  // TODO: order with type in mind based on field definitions

  const fieldKey = arrangement.orderBy

  if (!fieldKey) {
    return cards
  }

  return cards.sort((a, b) => {
    const isDescending = arrangement.descendingOrder === true
    const valueA = isDescending ? b[fieldKey] : a[fieldKey]
    const valueB = isDescending ? a[fieldKey] : b[fieldKey]

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return valueA.toUpperCase().localeCompare(valueB.toUpperCase())
    } else if (typeof valueA !== 'object' && typeof valueB !== 'object') {
      return valueA - valueB
    }

    return 0
  })
}

export function groupCards (arrangement, cards, definedFields) {
  const fieldKey = arrangement.groupBy
  const sections = {}

  if (definedFields[fieldKey] && Array.isArray(definedFields[fieldKey].options)) {
    for (const option of definedFields[fieldKey].options) {
      sections[option] = []
    }
  }

  for (const card of cards) {
    const name = card[fieldKey]

    if (sections[name] == null) {
      sections[name] = []
    }

    sections[name].push(card)
  }

  return sections
}
