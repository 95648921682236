<script>
export default {
  name: 'BaseMessage',

  props: {
    message: String,
    type: String,
    info: String
  },

  computed: {
    cssClass () {
      return (this.type) ? 'message--' + this.type : ''
    }
  }
}
</script>

<template>
  <p class="message"
     :class="cssClass">
    {{ message }}
    <span class="message__info">
      {{ info }}
    </span>
  </p>
</template>

<style>
.message {
    padding: 10px;
    border-radius: 3px;
    background-color: #ccc;
}

.message__info {
  float: right;
}

.message--error {
    background-color: #bb6262;
}
</style>
