<script>
import { db } from '@/functions/database'
import lm from 'legible-mergeable'
import { generateFile, downloadFile } from '@/functions/local-file-export'

export default {
  data () {
    return {
      availableListIds: [],
      selectedListIds: [],
      takeEverything: false,
      isExporting: false,
      errorMessage: ''
    }
  },

  async mounted () {
    await this.load()
  },

  methods: {
    async load () {
      this.availableListIds = lm.map(await db.get('index', 'list'), (list) => list.id)
    },

    async generateFile () {
      if (this.isExporting) {
        return
      }

      const date = (new Date()).toISOString().slice(0, 10)

      this.isExporting = true

      try {
        const blob = await generateFile(this.takeEverything, this.selectedListIds)

        if (this.takeEverything) {
          downloadFile(`all lists on ${date}.bro`, blob)
        } else {
          downloadFile(`${this.selectedListIds.join(' ')} ${date}.bro`, blob)
        }
      } catch (error) {
        this.errorMessage = error
      }

      this.isExporting = false
    }
  }
}
</script>

<template>
  <div>
    <p v-if="errorMessage" class="text-warning">{{ errorMessage }}</p>

    <p>
      <label>
        <input v-model="takeEverything" :value="true" type="radio" />
        <span class="title">Take all lists including their sorting on the start page and the information which lists have been removed or deleted.</span>
      </label>
    <p>

    <p style="text-align: center; margin: 20px 10px">— or —</p>

    <p>
      <label>
        <input v-model="takeEverything" :value="false" type="radio" />
        <span class="title">Take a list selection of your choice without information of their sorting on the start page.</span>
      </label>
    <p>

    <div v-for="listId in availableListIds" class="list-item" :key="listId">
      <label>
        <input
          v-model="selectedListIds"
          :value="listId"
          :disabled="takeEverything"
          type="checkbox"
        />

        <span class="title">{{ listId }}</span>
      </label>
    </div>

    <p>
      <button
        class="button primary"
        :disabled="isExporting || (!takeEverything && selectedListIds.length === 0)"
        @click="generateFile"
      >
        <BaseSpinner v-if="isExporting" color="#000" />
        <span v-else>Generate and download</span>
      </button>
    </p>
  </div>
</template>

<style scoped>
.list-item {
  margin: 15px 20px;
}

label > .title {
  padding-left: 10px;
}
</style>
