<script>
import * as db from '@/functions/database'

export default {
  data () {
    return {
      databases: [],
      showInfo: false
    }
  },

  mounted () {
    this.load()

    db.closeCurrentDatabase()
  },

  computed: {
    logoUrl () {
      return process.env.BASE_URL + 'icons/android-chrome-192x192.png'
    }
  },

  methods: {
    load () {
      this.databases = db.availableDatabases()
    },

    create () {
      const label = window.prompt('Please enter the name for the new database')

      if (!label) {
        return
      }

      db.create({ label })

      this.load()
    },

    async open (databaseItem) {
      await db.openAndMigrateDatabase(databaseItem)

      this.$router.push({ name: 'start' })
    },

    async drop (databaseItem) {
      const confirmed = window.confirm(`You are in the process to delete the database "${databaseItem.label}" (Id: "${databaseItem.id}"). This can't be undone.`)

      if (!confirmed) {
        return
      }

      await db.deleteDatabase(databaseItem)

      this.load()
    }
  }
}
</script>

<template>
  <BaseViewTemplate>
    <div slot="header">
      <div class="header">
        <img class="logo" :src="logoUrl" />

        <span>Brokkoli Report</span>
      </div>

      <button class="button" @click="$store.dispatch('dialog/create', { componentName: 'SystemMenu' })">
        …
      </button>
    </div>

    <div class="flex align-items-center justify-content-space-between">
      <h2>
        Databases

        &nbsp;
        <small class="text-underline font-weight-normal" @click="showInfo = !showInfo"><small>What?</small></small>
      </h2>

      <div class="mx-2">
        <button class="button primary" @click="create">
          + Create New
        </button>
      </div>
    </div>

    <p v-if="showInfo">
      Databases are complete separated containers of lists.
      They don't know of each other and can't access content among themselves.
      Each database can be either used only locally or synced to a webdav server with one specific webdav account.
      What databases you have is completely local to your device.
    </p>

    <br>

    <p v-if="databases.length === 0">
      No databases found. You first need to create a database.
    </p>

    <div class="list-item" v-for="database in databases" :key="database.id">
      <strong>{{ database.label }}</strong> (Id: {{ database.id }})

      <div class="button-row">
        <button @click="drop(database)" class="button destructive">
          Delete
        </button>
        <button @click="open(database)" class="button">
          Open
        </button>
      </div>
    </div>

    <br>

    <p>If you used the app before version 2022.5.0-alpha then please click below, export everything as file and import it into a new database. This option will soon be unavailable.</p>

    <button @click="open()" class="button full">
      Open old default database
    </button>
  </BaseViewTemplate>
</template>

<style scoped>
.header {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.header > img {
  margin-right: 20px;
}

.create-button {
}

.list-item {
  margin: 20px 10px;
}

.button.destructive {
  width: 100px;
}

.logo {
  width: 36px;
  margin: 8px 0;
}
</style>
