<template>
  <div class="view"
       :class="{ stickyHeader, stickyFooter }">
    <header v-if="showHeader"
            :style="styleHeaderFooter">
      <slot name="header" />
    </header>

    <main>
      <slot />
    </main>

    <footer v-if="isFooterVisible"
            :style="styleHeaderFooter">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'BaseViewTemplate',

  props: {
    stickyHeader: {
      default: false,
      type: Boolean
    },

    backgroundHeaderFooter: {
      type: String
    },

    hideFooter: {
      type: Boolean,
      default: false
    },

    stickyFooter: {
      default: true,
      type: Boolean
    }
  },

  computed: {
    styleHeaderFooter () {
      if (this.backgroundHeaderFooter) {
        return { background: this.backgroundHeaderFooter }
      }
      return {}
    },

    showHeader () {
      return {}.hasOwnProperty.call(this.$slots, 'header')
    },

    isFooterVisible () {
      return {}.hasOwnProperty.call(this.$slots, 'footer') && !this.hideFooter
    }
  },

  methods: {
    goBack () {
      this.$router.options.goOneLevelUp(this.$router)
    }
  }
}
</script>

<style>
.view > main {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.view.stickyFooter > main {
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
}

.view.stickyHeader > main {
  padding-top: 54px;
}

.view.stickyHeader > header {
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
  height: 54px;
  border-bottom: 1px solid;
}

.view > header h1,
.view > header h5 {
  margin: 8px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.view > header > div > button {
  width: auto;
  margin: 10px 0;
}

header .button,
footer .button {
  min-width: 50px;
}

header .button:not(.primary):not(.destructive),
footer .button:not(.primary):not(.destructive) {
  border-color: transparent;
  background-color: #00000050;
}

header .button.primary,
footer .button.primary {
  background-color: #fff !important;
  color: #585858 !important;
}

.view.stickyFooter > footer {
  position: fixed;
  z-index: 3;
  width: 100%;
  bottom: 0;
  height: calc(50px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
}

.input-focused .view > footer {
  position: static;
}

@media (orientation: landscape) and (max-height: 500px) {
  .view > header,
  .view > main,
  .view > footer {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  .view.stickyFooter > footer {
    position: static;
  }
}

.view > header,
.view > footer {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #444;
}

.view > footer > div,
.view > header > div {
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
</style>
