<script>
import { evaluator } from '@/functions/evaluator'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import Mustache from 'mustache'

export default {
  props: {
    cards: {
      type: Array,
      required: true
    },

    list: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      markdown: null
    }
  },

  async mounted () {
    // TODO: every time the cards change, it needs to rerender..
    this.markdown = await this.renderMarkdown()
  },

  methods: {
    async renderMarkdown () {
      if (!this.list.prepareSummaryScript || !this.list.summaryMarkup) {
        return
      }

      try {
        const payload = await evaluator(this.list.prepareSummaryScript, {
          list: this.list,
          // TODO: are cards expected to have a certain order?
          cards: this.cards
        })

        let result = Mustache.render(this.list.summaryMarkup, payload)
        result = marked(result)
        result = DOMPurify.sanitize(result)

        return result
      } catch (error) {
        this.$emit('error', {
          info: 'prepareSummaryScript:' + error.location,
          type: 'error',
          message: error.message
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="markdown" v-html="markdown" />
  </div>
</template>

<style>
</style>
