<script>
import { prepareForImport, importByActions } from '@/functions/local-file-import'

export default {
  data () {
    return {
      isImporting: false,
      actions: [],
      blob: null,
      hasIndex: null,
      mergedIndex: null,
      isPrepared: false,
      errorMessage: ''
    }
  },

  computed: {
    hasSkipped () {
      return this.actions.some((action) => action.skipReasons.length > 0)
    },

    invalid () {
      return this.actions.length === 0 || (this.hasIndex && this.hasSkipped)
    },

    operationLabels () {
      return {
        TRY_MERGE: 'Try merge',
        TRY_HIDDEN_MERGE: 'Try merge (without recovery)',
        MERGE: 'Reposition',
        // "CHANGE" doesn't make any sense in the UI, way to low level
        CHANGE: 'Merge',
        ADD: 'Add',
        REMOVE: 'Remove',
        RECOVER: 'Recover'
      }
    },

    skipReasonLabels () {
      return {
        CARDS_NOT_FOUND: 'Cards file not found',
        CONFIG_NOT_FOUND: 'Config file not found',
        ID_NOT_MATCHING_PATTERN: 'List id is invalid'
      }
    }
  },

  methods: {
    async selectFile (event) {
      const files = event.target.files || event.dataTransfer.files

      try {
        this.blob = files[0]

        const preparation = await prepareForImport(this.blob)

        this.isPrepared = true

        this.actions = preparation.actions
        this.hasIndex = preparation.hasIndex
        this.mergedIndex = preparation.mergedIndex
      } catch (error) {
        this.errorMessage = error
        throw error
      }
    },

    async startImport () {
      this.isImporting = true

      try {
        await importByActions(this.blob, this.actions, this.mergedIndex)
      } catch (error) {
        this.errorMessage = error
        throw error
      }

      this.$store.dispatch('alert/success', { text: 'Import successfull' })
      this.isImporting = false
      this.isPrepared = false
    }
  }
}
</script>

<template>
  <div>
    <p v-if="errorMessage" class="text-warning">{{ errorMessage }}</p>

    <p>
      <input
        class="button primary"
        accept="application/zip,.bro"
        style="text-transform: none"
        type="file"
        @change="selectFile"
      />
    </p>

    <div v-if="isPrepared">
      <p>
        <span v-if="hasIndex">
          Index file found. Looking for lists inside the index file..
        </span>
        <span v-else>
          No index file found. Looking for standalone list files...
        </span>
      </p>

      <p v-if="invalid" class="text-warning">
        The import can't continue.

        <span v-show="actions.length === 0">No valid lists found to import.</span>
        <span v-show="hasIndex && hasSkipped">
          No skipped lists allowed in an index file import.
        </span>
      </p>

      <p v-else>
        The import will do the following:
      </p>

      <ul>
        <li
          v-for="action in actions"
          class="action-item"
          :class="{ '-skip': action.skipReasons.length > 0 }"
          :key="action.listId"
        >
          <span class="operation">{{ operationLabels[action.operation] }}:</span>
          <span>{{ action.listId }}</span>

          <span v-show="action.skipReasons.length > 0">
            <br>Skipped because: {{ action.skipReasons.map(v => skipReasonLabels[v]).join(', ') }}
          </span>
        </li>
      </ul>

      <p>
        <button class="button primary" :disabled="invalid || isImporting" @click="startImport">
          <BaseSpinner v-if="isImporting" color="#000" />
          <span v-else>Import</span>
        </button>
      </p>
    </div>
  </div>
</template>

<style scoped>
.action-item. {
  margin-bottom: 5px;
}

.action-item > .operation {
  margin-right: 5px;
}

.action-item.-skip > .operation {
  text-decoration: line-through;
}
</style>
