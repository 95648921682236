<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },

    value: {
      type: Array
    },

    creatable: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selected: [],
      input: '',
      preselected: null,
      dialogVisible: false
    }
  },

  computed: {
    doneButtonOptions () {
      return {
        label: `Done (${this.selected.length})`,
        class: 'primary'
      }
    },

    filteredOptions () {
      const options = this.$helper.uniquenizeArray([
        ...this.options,
        ...this.selected
      ])

      return options.filter(item => {
        const string = (item.label ?? item).toLowerCase()
        return string.includes(this.input)
      })
    },

    showNewOption () {
      if (this.input === '' || !this.creatable) {
        return false
      }

      return !this.filteredOptions.some(item => {
        return (item.label ?? item).toLowerCase() === this.input
      })
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.setSelected()
      }
    }
  },

  methods: {
    key (type, item) {
      // If multiple field-lists components are used in parallel this component
      // should get a key="unique string" prop, which will be inside $vnode.key.
      return `multiselect-${this.$vnode.key}-${type}-${item.key ? item.key : item}`
    },

    setSelected (newValue) {
      this.selected = JSON.parse(JSON.stringify(this.value)) ?? []
    },

    toggleOption ({ option, reset }) {
      if (option == null) {
        return
      }

      const index = this.getIndexOfOption(option)

      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(option)
      }

      this.changed()

      if (reset) {
        this.resetPreselected()
      } else {
        this.$refs.input.select()
      }
    },

    movePreselectionUp () {
      if (
        (this.preselected > -1 && this.showNewOption) ||
        this.preselected > 0
      ) {
        this.preselected = this.preselected - 1
      } else {
        const maxIndex = this.filteredOptions.length - 1
        this.preselected = maxIndex
      }
    },

    movePreselectionDown () {
      const maxIndex = this.filteredOptions.length - 1
      if (this.preselected == null || this.preselected >= maxIndex) {
        this.resetPreselected({ selectFirst: true })
      } else {
        this.preselected = this.preselected + 1
      }
    },

    preselectOption (option) {
      const index = this.filteredOptions.findIndex(item => {
        return JSON.stringify(item) === JSON.stringify(option)
      })
      this.preselected = index
    },

    inputEnter ({ reset }) {
      if (this.preselected == null) {
        this.closeDialog()
      }

      let option = this.filteredOptions[this.preselected]

      if (this.preselected < 0 && this.showNewOption) {
        option = this.input
      }

      if (option != null) {
        this.toggleOption({ option })
      }
    },

    getIndexOfOption (option) {
      return this.selected.findIndex(item => {
        return JSON.stringify(item) === JSON.stringify(option)
      })
    },

    changedInput () {
      this.resetPreselected({ selectFirst: true })
    },

    resetPreselected (options) {
      const nullValue = options?.selectFirst ? 0 : null
      this.preselected = this.showNewOption ? -1 : nullValue
    },

    changed () {
      this.$emit('change', this.selected)
      this.$emit('input', this.selected)
    },

    openDialog () {
      if (!this.disabled) {
        this.dialogVisible = true
        setTimeout(() => this.$refs.input.focus(), 0)
      }
    },

    closeDialog () {
      this.input = ''
      this.resetPreselected()
      this.dialogVisible = false
    }
  }
}
</script>

<template>
  <div class="multiselect">
    <div class="multiselect__input"
         tabindex="0"
         @click="openDialog"
         @keyup.enter="openDialog">

      <div class="multiselect__inputItem"
           v-for="item of selected"
           :key="key('item', item)"
           :value="item.key ? item.key : item">
        {{ item.label ? item.label : item }},&nbsp;
      </div>

      <div v-show="selected.length === 0">Nothing selected</div>
    </div>

    <BaseDialog name="multiselect"
                :visible="dialogVisible"
                :cancelOptions="doneButtonOptions"
                @close="closeDialog">
      <div class="multiselect__options">
        <div v-for="(option, index) of filteredOptions"
             class="multiselect__option"
             :class="{
               'multiselect__option--preselected': preselected === index,
               'multiselect__option--selected': getIndexOfOption(option) >= 0
             }"
             @click.prevent="toggleOption({ option, reset: true })"
             @mouseover="preselectOption(option)"
             :key="key('option', option)"
             :value="option.key ? option.key : option">
          {{ option.label ? option.label : option }}
        </div>

        <div v-show="showNewOption"
             class="multiselect__option"
             :class="{ 'multiselect__option--preselected': preselected === -1 }"
             @mouseover="resetPreselected()"
             @click="toggleOption({ option: input, reset: true })">
          create {{ input }}
        </div>
      </div>

      <input v-model="input"
             placeholder="Filter..."
             ref="input"
             @input="changedInput"
             @keyup.enter="inputEnter"
             @keydown.up="movePreselectionUp()"
             @keydown.down="movePreselectionDown()"
             class="form-field__text multiselect__inputField" />
    </BaseDialog>
  </div>
</template>

<style>
.multiselect__input {
  padding: 8px;
  font-size: inherit;
  margin: 2px 0;
  background-color: #333;
  color: #fff;
  position: relative;
}

.multiselect__inputItem {
  display: inline;
}

.multiselect__inputField {
  padding-left: 10px;
}

.multiselect__options {
  background-color: #333;
}

.multiselect__option {
  padding: 6px 10px;
  background-color: #262626;
  border-top: 1px solid #333;
  cursor: pointer;
}

.multiselect__option--preselected {
  background-color: #666;
}

.multiselect__option--selected:after {
  content: '✓';
  position: absolute;
  right: 10px;
}
</style>
