<template>
  <div class="card-item">
    <label v-if="isCheckable">
      <input type="checkbox" :checked="isChecked" @change="toggleCheckbox()" />
    </label>

    <div class="card-item__headers"
         @click="click()"
         @keyup.enter="click()"
         tabindex="0">
      <BaseSpinner v-if="loading" />

      <h3 v-if="!loading"
          class="card-item__header"
          :style="{ backgroundImage: gradient }">
        {{ header }}
      </h3>

      <div v-if="!loading && subheader"
           class="card-item__subheader">
        {{ subheader }}
      </div>
    </div>
  </div>
</template>

<script>
import { evaluator } from '@/functions/evaluator'
import lm from 'legible-mergeable'

export default {
  props: {
    list: {
      required: true,
      type: Object
    },

    card: {
      required: true,
      type: Object
    },

    checkboxField: {
      type: Object
    }
  },

  data () {
    return {
      header: null,
      subheader: null,
      loading: false
    }
  },

  computed: {
    isChecked () {
      return this.isCheckable && this.card[this.checkboxField.fieldKey] === this.checkboxField.trueValue
    },

    isCheckable () {
      return this.checkboxField != null
    },

    gradient () {
      return this.$helper.cssBasicHueGradient(this.list.hue)
    }
  },

  mounted () {
    this.evaluateHeader()
  },

  methods: {
    click () {
      this.$emit('click', this.card)
    },

    async toggleCheckbox () {
      const newValue = this.isChecked ? this.checkboxField.falseValue : this.checkboxField.trueValue

      lm.set(this.card, this.checkboxField.fieldKey, newValue)

      this.$emit('update', this.card)
    },

    evaluateHeader () {
      this.loading = true

      evaluator(this.list.header, { card: this.card })
        .then(header => {
          this.header = header.primary
          this.subheader = header.secondary
          this.loading = false
        })
        .catch(error => {
          this.header = error.displayMessage
          this.loading = false
        })
    }
  }
}
</script>

<style>
.card-item {
  padding: 0 10px 5px 10px;
  margin-top: 7px;
  cursor: pointer;
  display: flex;
  background-color: #262626;
}

.card-item:not(:last-child) {
  margin-bottom: 3px;
  border-bottom: 1px solid #444;
}

.card-item__headers {
  flex-grow: 2;
}

.card-item__header {
  display: inline-block;
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  background-clip: text;
  color: transparent;
}

.card-item > label {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.card-item__subheader {
  margin-top: 3px;
}
</style>
