import Vue from 'vue'
import Vuex from 'vuex'

import alert from './alert'
import dialog from './dialog'
import serviceWorker, { addEventListenerPlugin } from './service-worker'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
    alert,
    dialog,
    serviceWorker
  },
  plugins: [
    addEventListenerPlugin
  ]
})
