function grow (el) {
  el.style.resize = 'none'
  el.style.overflowY = 'hidden'

  const styleHeight = parseInt(el.style.height.replace('px', '') || 0)

  if (el.scrollHeight > styleHeight) {
    el.style.height = (el.scrollHeight) + 'px'
  }
}

export default {
  inserted: grow,
  update: grow
}
