<script>
import { db } from '@/functions/database'
import { cleanupRemovedLists } from '@/functions/storage'
import { HTTP_STATUS, authenticate, fetchAll } from '@/functions/remote-sync'

export default {
  name: 'Login',

  data () {
    return {
      errorMessage: '',
      isLoading: true,
      username: '',
      password: '',
      url: ''
    }
  },

  async mounted () {
    const connection = db.transaction('connection').store

    this.username = await connection.get('username')
    this.url = await connection.get('baseUrl')

    this.isLoading = false
  },

  methods: {
    goToStart () {
      this.$router.replace({ name: 'start' })
    },

    login () {
      this.isLoading = true
      this.errorMessage = ''

      authenticate(this.url, this.username, this.password).then(async ({ response }) => {
        if (response.status === HTTP_STATUS.UNAUTHORIZED) {
          this.errorMessage = 'Incorrect Credentials'
          this.isLoading = false
          return
        }

        if (response.status === HTTP_STATUS.NOT_FOUND) {
          const text = 'Successfull Login! However no index file was found on the server, please upload your lists.'
          this.$store.dispatch('alert/warn', { text })
          this.isLoading = false
          this.goToStart()
          return
        }

        if (response.status !== HTTP_STATUS.OK) {
          this.errorMessage = `Something went wrong. Please make sure the server is a correctly configured webdav server. (Response Status Code: ${response.status})`
          this.isLoading = false
          return
        }

        await fetchAll()
        cleanupRemovedLists()

        this.$store.dispatch('alert/success', { text: 'Downloaded all lists' })

        await this.$helper.sleep(1000)

        this.goToStart()
      }).catch(error => {
        this.errorMessage = error
        this.isLoading = false
      })
    }
  }
}
</script>

<template>
  <BaseViewTemplate>
    <div slot="header">
      <h5>Login</h5>
    </div>

    <p v-show="errorMessage"
       v-text="errorMessage"
       class="text-warning" />

    <form class="form"
          @submit.prevent="login()"
          id="loginForm">
      <label>
        <span class="title">Username</span>
        <input type="text"
               v-model="username">
      </label>

      <label>
        <span class="title">Password</span>
        <input type="password"
               v-model="password">
      </label>

      <label>
        <span class="title">Server Url</span>
        <input v-model="url" type="text">
      </label>
    </form>

    <div class="lonely-centered">
      <BaseSpinner v-show="isLoading" />
    </div>

    <div slot="footer">
      <button class="button"
              @click="goToStart()">
        Go To Start
      </button>

      <button type="submit"
              class="button primary"
              :disabled="isLoading"
              form="loginForm">
        Login
      </button>
    </div>
  </BaseViewTemplate>
</template>
