<template>
  <BaseViewTemplate :backgroundHeaderFooter="previewBackgroundGradient">
    <div slot="header">
      <h5>Add</h5>
    </div>

    <BaseMessage v-bind="error" v-if="error" />
    <div v-if="isLoading"
         key="spinner"
         class="lonely-centered">
      <BaseSpinner />
    </div>

    <div v-if="!isLoading" key="content">
      <Field v-for="field in fields"
             :key="field.key"
             :field="field"
             v-model="card[field.key]" />
    </div>

    <div slot="footer">
      <button class="button" @click="$router.back()">◁</button>

      <button class="button primary"
              @click="persist()">
        Add
      </button>
    </div>
  </BaseViewTemplate>
</template>

<script>
import { evaluator } from '@/functions/evaluator'
import Field from '@/components/field'
import { db } from '@/functions/database'
import { updateCard } from '@/functions/storage'

const INITIAL_DATA = () => ({
  list: null,
  fields: [],

  card: {},

  isLoading: true,
  error: null
})

export default {
  name: 'CardAdd',

  data: INITIAL_DATA,

  computed: {
    listId () {
      return this.$route.params.listId
    },

    previewBackgroundGradient () {
      if (!this.list) {
        return
      }

      return this.$helper.cssBasicHueGradient(this.list.hue)
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler () {
        this.reset()
        this.load()
      }
    }
  },

  methods: {
    setEvaluatorError (functionName) {
      return (error) => {
        this.error = {
          info: functionName + ':' + error.location,
          type: 'error',
          message: error.message
        }
      }
    },

    async persist () {
      this.card.id = this.$helper.generateRandomId(10)

      await updateCard({
        listId: this.listId,
        newCard: this.card
      })

      this.$router.replace({ name: 'cardDetail', params: { cardId: this.card.id } })
    },

    reset () {
      Object.assign(this.$data, INITIAL_DATA())
    },

    computeFields () {
      const setFields = evaluated => {
        this.fields = Object.keys(evaluated).map(key => {
          return { key, ...evaluated[key] }
        })
      }

      return evaluator(this.list.fields)
        .then(setFields)
        .catch(this.setEvaluatorError('fieldOptions'))
    },

    async load () {
      this.isLoading = true

      this.list = await db.get('config', this.listId)

      await this.computeFields()

      this.isLoading = false
    }
  },

  components: {
    Field
  }
}
</script>
