<template>
  <BaseViewTemplate :backgroundHeaderFooter="gradient">
    <div slot="header">
      <h5>{{ this.list ? this.list.name : '...' }} Config</h5>
    </div>

    <div v-if="list" class="form">
      <label>
        <span class="title">Name</span>
        <input type="text"
               v-model="list.name" />
        <p class="text text-warning"
           v-if="!isNameValid">
          A list name is required
        </p>
      </label>

      <label>
        <span class="title">Id</span>
        <input type="text"
               disabled
               :value="list.id">
        <p class="text">
          The list id is also the filename on the server and can't be changed after creation.
        </p>
      </label>

      <label>
        <span class="title">
          Color (Hue {{ list.hue }}°)
        </span>

        <FieldHueColorRange v-model="list.hue" @gradient="gradient = $event" />
      </label>

      <label>
        <span class="title">Flags</span>
        <FieldMultiselect :value="list.flags"
                          @change="list.flags = $event"
                          key="flags"
                          :disabled="isReadonly"
                          :creatable="true"
                          :options="availableFlags" />
      </label>

      <p class="my-4"><em>Danger! Be careful. The input fields below require (basic) javascript coding knowledge!</em></p>

      <label>
        <span class="title">Field Definitions</span>
        <textarea v-model="list.fields"
                  v-autogrow
                  class="code">
        </textarea>
      </label>

      <label>
        <span class="title">Header</span>
        <textarea v-model="list.header"
                  v-autogrow
                  class="code">
        </textarea>
      </label>

      <label>
        <span class="title">Arrange Cards</span>
        <textarea v-model="list.arrangeCards" v-autogrow class="code" />
      </label>

      <label>
        <p><code>prepareSummaryScript(args) {</code></p>
        <p><code>// args is an object with { cards, list }</code></p>
        <textarea v-model="list.prepareSummaryScript" v-autogrow class="code -indent" placeholder="return { foo: 'bar' }" />
        <p><code>}</code></p>
      </label>

      <label>
        <span class="title">Summary Markup</span>
        <p class="form-field__info">Use the return value of script above with mustache templating. Gets parsed as markdown.</p>
        <textarea v-model="list.summaryMarkup" v-autogrow class="code" :placeholder="'Value of *foo*: `{{foo}}`'" />
      </label>

      <p>
        <button class="button destructive"
                :disabled="disableDelete"
                @click="removeList">
          Remove List
        </button>
      </p>
    </div>

    <div slot="footer">
      <button class="button" @click="$router.back()">◁</button>

      <button class="button primary"
              :disabled="disableSave"
              @click="save()">
        Save
      </button>
    </div>
  </BaseViewTemplate>
</template>

<script>
import lm from 'legible-mergeable'
import { LIST_FLAGS } from '@/constants'
import autogrow from '@/directives/autogrow'
import FieldMultiselect from '@/components/field-multiselect'
import FieldHueColorRange from '@/components/field-hue-color-range'
import { db } from '@/functions/database'
import * as storage from '@/functions/storage'

export default {
  name: 'ListConfigEdit',
  data () {
    return {
      list: null,
      oldList: null,
      gradient: ''
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler () {
        this.reset()
        this.load()
      }
    }
  },

  methods: {
    async load () {
      this.isCodeVisible = true
      this.oldList = await db.get('config', this.listId)
      this.list = lm.createProxy(this.oldList)
    },

    async save () {
      await storage.updateConfig({ config: lm.clone(this.list) })
      await this.load()
    },

    async removeList () {
      await storage.removeList({ listId: this.listId })
      this.$router.go(-2)
    },

    reset () {
      this.list = null
      this.oldList = null
    }
  },

  computed: {
    isChanged () {
      return JSON.stringify(lm.base(this.list)) !== JSON.stringify(lm.base(this.oldList))
    },

    listId () {
      return this.$route.params.listId
    },

    isNameValid () {
      return !!this.list && !!this.list.name && this.list.name.trim() !== ''
    },

    availableFlags () {
      return Object.values(LIST_FLAGS).filter(flag => flag[0] !== '_')
    },

    isReadonly () {
      return this.list.flags?.includes(LIST_FLAGS._NO_CONFIG_SAVE)
    },

    disableSave () {
      return !this.list || !this.isChanged || !this.isNameValid || this.isReadonly
    },

    disableDelete () {
      return this.list.flags?.includes(LIST_FLAGS._NO_CONFIG_DELETE)
    }
  },

  directives: {
    autogrow
  },

  components: {
    FieldMultiselect,
    FieldHueColorRange
  }
}
</script>
