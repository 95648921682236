import JSZip from 'jszip'
import lm from 'legible-mergeable'

import { toFile, getFilename } from './mergeable-file-transformer'
import { db } from './database'

export async function generateFile (takeEverything, listIds) {
  const zip = new JSZip()

  if (takeEverything) {
    const index = await db.get('index', 'list')
    zip.file(getFilename('index', 'list'), toFile(index, 'index'))

    listIds = lm.map(index, (list) => list.id)
  }

  for (const listId of listIds) {
    zip.file(
      getFilename('config', listId),
      toFile(await db.get('config', listId), 'config')
    )
    zip.file(
      getFilename('cards', listId),
      toFile(await db.get('cards', listId), 'cards')
    )
  }

  return zip.generateAsync({ type: 'blob' })
}

export function downloadFile (filename, blob) {
  const element = document.createElement('a')

  element.setAttribute('href', URL.createObjectURL(blob))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
