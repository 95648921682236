<script>
/*
 * A global dialogs visibility is controlled by the global dialog store.
 *
 * Create and show an instance of a dialog component:
 * this.$store.dispatch('dialog/create', { componentName: 'The Component Name' })
 */
export default {
  name: 'BaseGlobalDialog',

  props: {
    dialogId: {
      type: String,
      required: true
    },

    cancelOptions: {
      type: Object
    }
  },

  computed: {
    isVisible () {
      return this.$store.getters['dialog/isVisible'](this.dialogId)
    }
  },

  watch: {
    isVisible (isVisible) {
      if (isVisible) {
        setTimeout(() => this.$emit('show'), 0)
      } else {
        this.$emit('close')
      }
    }
  },

  methods: {
    close () {
      this.$store.commit('dialog/close', { id: this.dialogId })
      this.$emit('close')
    }
  }
}
</script>

<template>
  <BaseDialog
    :visible="isVisible"
    :initial-dialog-id="dialogId"
    v-bind="$attrs"
    @close="close"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </BaseDialog>
</template>
