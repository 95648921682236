<template>
  <div class="alert"
       :class="[{ 'alert--show': display }, 'alert--' + type]"
       @click="hide()">
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  methods: {
    hide () {
      this.$store.commit('alert/hide')
    }
  },

  computed: {
    ...mapState('alert', ['display', 'text', 'type'])
  }
}
</script>

<style>
.alert {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    color: #fff;
    opacity: 0;
    position: fixed;
    z-index: 150;
    top: -100px;
    left: 10px;
    right: 10px;
    border-radius: 9px;
    transition: opacity 300ms cubic-bezier(0.6, 0.21, 0.35, 0.86),
                top 300ms cubic-bezier(0.6, 0.21, 0.35, 0.86);
}

.alert.alert--show {
    top: 10px;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.6, 0.21, 0.35, 0.86),
                top 300ms cubic-bezier(0.6, 0.21, 0.35, 0.86);
}

.alert.alert--log {
    background-color: #d8d8d8;
    color: #1d1d1d;
    box-shadow: 0 0 20px -5px #1d1d1d;
}

.alert.alert--error {
    color: #fff;
    background-color: #da3f3f;
    box-shadow: 0 0 20px -5px #da3f3f;
}

.alert.alert--warn {
    color: #fff;
    background-color: #e2b040;
    box-shadow: 0 0 20px -5px #e2b040;
}

.alert.alert--success {
    color: #fff;
    background-color: #79bc5d;
    box-shadow: 0 0 20px -5px #79bc5d;
}
</style>
