<script>
export default {
  props: {
    value: {
      type: Number
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      gradient: ''
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (hue) {
        this.setGradient(hue)
      }
    }
  },

  mounted () {
    this.input(this.value)
  },

  methods: {
    input (hue) {
      hue = +hue

      this.$emit('input', hue)
    },

    setGradient (hue) {
      this.gradient = this.$helper.cssBasicHueGradient(+hue)
      this.$emit('gradient', this.gradient)
    }
  }
}
</script>

<template>
  <input
    type="range"
    max="360"
    @input="input($event.target.value)"
    class="hue-color-range"
    :value="value"
    :style="{ '--gradient': gradient }"
    :disabled="disabled"
  >
</template>

<style>
.hue-color-range {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  margin: 0;
}

.hue-color-range:focus {
  outline: none;
  box-shadow: none;
}

.hue-color-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: var(--gradient);
  height: 10px;
}

.hue-color-range::-moz-range-track {
  background: var(--gradient);
  height: 10px;
}

.hue-color-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid black;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -5px;
}

.hue-color-range::-moz-range-thumb {
  border: 2px solid black;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
}
</style>
