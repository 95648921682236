<template>
  <div class="form-field">
    <div class="form-field__header">
      <label>{{ label }}</label>

      <div>
        <span v-if="isMarkdownRenderingAvailable && !showAsMarkdown" class="text-underline" @click="showAsMarkdown = true">Show Preview</span>
        <span v-if="isMarkdownRenderingAvailable && showAsMarkdown" class="text-underline" @click="showAsMarkdown = false">Edit Field</span>
      </div>
    </div>

    <input v-if="type === 'text'"
           class="form-field__text"
           :value="getValue"
           :readonly="readonly"
           type="text"
           @input="change($event.target.value)" />

    <textarea v-if="type === 'long' && !showAsMarkdown"
              class="form-field__textarea"
              :value="value"
              v-autogrow
              @input="change($event.target.value)" />

    <div v-if="type === 'long' && showAsMarkdown" class="markdown" v-html="renderedMarkdown" />

    <select v-if="type === 'select'"
            class="form-field__select"
            @change="change($event.target.value)"
            :value="value">

      <option :value="null">-</option>
      <option v-for="option of options"
              :key="`select-${field.key}-${option.key ? option.key : option}`"
              :value="option.key ? option.key : option">
        {{ option.label ? option.label : option }}
      </option>
    </select>

    <FieldMultiselect v-if="type === 'multiselect'"
                      @change="change"
                      :key="field.key"
                      :creatable="field.creatable"
                      :value="value"
                      :options="options" />

    <input v-if="type === 'date'"
           class="form-field__text"
           @input="change($event.target.value)"
           :value="value"
           type="date" />
  </div>
</template>

<script>
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import autogrow from '@/directives/autogrow'
import FieldMultiselect from '@/components/field-multiselect'

export default {
  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      required: true,
      default: function () {
        if (this.field.default != null) {
          this.$emit('input', this.field.default)
          return this.field.default
        }

        return null
      }
    }
  },

  data () {
    return {
      showAsMarkdown: false
    }
  },

  mounted () {
    if (this.field.renderMarkdown) {
      // TODO: set this somewhere global?
      marked.use({
        renderer: {
          table (header, body) {
            if (body) body = `<tbody>${body}</tbody>`
            const table = `<table>\n<thead>\n${header}</thead>\n${body}</table>`
            return `<div class="markdown-table-container">\n${table}\n</div>\n`
          }
        }
      })

      if (this.isMarkdownRenderingAvailable && this.field.type === 'long') {
        this.showAsMarkdown = true
      }
    }
  },

  computed: {
    getValue () {
      if (this.stringified) {
        return JSON.stringify(this.value)
      }

      return this.value
    },

    readonly () {
      return this.stringified
    },

    stringified () {
      return typeof this.value === 'object' && this.value !== null
    },

    label () {
      return this.field.label || this.field.key
    },

    type () {
      return this.field.type || 'text'
    },

    options () {
      return this.field.options || []
    },

    isMarkdownRenderingAvailable () {
      return this.field.renderMarkdown === true && this.value != null
    },

    renderedMarkdown () {
      if (!this.isMarkdownRenderingAvailable || !this.showAsMarkdown) {
        return null
      }

      return DOMPurify.sanitize(marked(this.value))
    }
  },

  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },

  directives: {
    autogrow
  },

  components: {
    FieldMultiselect
  }
}
</script>

<style>
</style>
