import lm from 'legible-mergeable'
import helper from '@/helper'
import { queueWriteToRemote, queueDeleteOnRemote } from './remote-sync'
import { db } from './database'

export async function writeToStore (tx, storeName, value, key) {
  const store = tx.objectStore(storeName)

  if (store.keyPath == null) {
    await store.put(value, key)
  } else {
    key = value[store.keyPath]
    await store.put(value)
  }

  await queueWriteToRemote(tx, storeName, key)
}

function addListToIndex (index, id) {
  lm.set(index, id, { id })
  lm.push(index, id)
}

export async function updateListIndex ({ newListIndex }) {
  const tx = db.transaction(['index', 'queue'], 'readwrite')
  const storageListIndex = await tx.objectStore('index').get('list')

  const listIndex = lm.merge(newListIndex, storageListIndex)

  await writeToStore(tx, 'index', listIndex, 'list')
  await tx.done

  return listIndex
}

export async function createList ({ config }) {
  const tx = db.transaction(['index', 'config', 'queue'], 'readwrite')
  const index = await tx.objectStore('index').get('list')

  addListToIndex(index, config.id)
  config.created = (new Date()).toISOString()

  await writeToStore(tx, 'index', index, 'list')
  await writeToStore(tx, 'config', lm.touch(config))
  await tx.done

  return config
}

export async function updateConfig ({ config }) {
  const tx = db.transaction(['config', 'queue'], 'readwrite')

  config = lm.merge(config, await tx.objectStore('config').get(config.id))

  await writeToStore(tx, 'config', config)
  await tx.done
}

export async function removeList ({ listId }) {
  const tx = db.transaction(['index', 'config', 'queue', 'cards'], 'readwrite')
  const index = await tx.objectStore('index').get('list')

  lm.drop(index, listId)

  await writeToStore(tx, 'index', index, 'list')
  await queueDeleteOnRemote(tx, 'config', listId)
  await queueDeleteOnRemote(tx, 'cards', listId)
  await tx.done
}

export async function deleteList ({ listId }) {
  const tx = db.transaction(['config', 'queue', 'cards'], 'readwrite')
  await tx.objectStore('config').delete(listId)
  await tx.objectStore('cards').delete(listId)
  await tx.done
}

export async function recoverList ({ listId }) {
  const tx = db.transaction(['index', 'config', 'queue', 'cards'], 'readwrite')
  const index = await tx.objectStore('index').get('list')

  addListToIndex(index, listId)

  await writeToStore(tx, 'index', index, 'list')
  await queueWriteToRemote(tx, 'config', listId)

  if (await tx.objectStore('cards').count(listId) > 0) {
    await queueWriteToRemote(tx, 'cards', listId)
  }

  await tx.done
}

export async function cleanupRemovedLists () {
  const sixDays = 1000 * 60 * 60 * 24 * 6
  const removedListIds = await getRemovedListIds()

  for (const list of removedListIds) {
    const msSinceRemove = (Date.now() - (new Date(list.removedAt)).getTime())
    if (sixDays < msSinceRemove) {
      await deleteList({ listId: list.id })
      console.log(`storage/cleanupRemovedLists of list ${list.id}`)
    }
  }
}

export async function getRemovedListIds () {
  const tx = db.transaction(['index', 'config'])
  const index = await tx.objectStore('index').get('list')
  // TODO: also get all cards keys to be really sure
  const allConfigs = await tx.objectStore('config').getAllKeys()

  const indexModifications = lm.modifications(index)

  return allConfigs
    .filter((id) => !helper.hasKey(index, id))
    .map((id) => ({ id, removedAt: indexModifications[id] }))
}

export async function updateCard ({ listId, newCard }) {
  const tx = db.transaction(['cards', 'queue'], 'readwrite')

  const cards = (await tx.objectStore('cards').get(listId)) || lm.touch({})

  if (cards[newCard.id] == null) {
    lm.set(cards, newCard.id, lm.touch(newCard))
  } else {
    cards[newCard.id] = lm.merge(newCard, cards[newCard.id])
  }

  await writeToStore(tx, 'cards', cards, listId)
  await tx.done
}

export async function deleteCard ({ listId, cardId }) {
  const tx = db.transaction(['cards', 'queue'], 'readwrite')

  const cards = (await tx.objectStore('cards').get(listId)) || lm.touch({})

  lm.drop(cards, cardId)

  await writeToStore(tx, 'cards', cards, listId)
  await tx.done
}
