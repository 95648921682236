import JSZip from 'jszip'
import lm from 'legible-mergeable'
import helper from '@/helper'

import { PATTERNS } from '@/constants'
import { queueWriteToRemote, queueDeleteOnRemote } from './remote-sync'
import { writeToStore } from './storage'
import { fromFile, getFilename } from './mergeable-file-transformer'
import { db } from './database'

/*
- TO: TOmbstone ("deleted")
- RDQ: Removed & delete Queued
- EX: EXistent
- EWQ: Existent & write Queued
- removed: the list is still recoverable
- deleted: the list are really gone
- y: local index
- x: the merge result from local and imported index

 \ x |
y \__| TO      | EX
-----|---------|--------
 TO  | TO      | EWQ (1)
 RDQ | RDQ     | EWQ (2)
 EX  | RDQ (4) | E?? (3)
 EWQ | RDQ (5) | EWQ

in 3 cases the status stays the same as in y, except for 5 exceptions. the exceptions
have a number next to them and are explained further down below.

- (1, 2, 3) a list is queued as WRITE if the merged index has it and the local doesn't
  - (3) ...except the local index has it already, then it will do a merge and check if it
    has changes. for this case it needs to be sure that no DELETE queue entry exists
  - (1) this is a "recovery"
  - (2) this could be advanced by leaving no queue entry behind if the merge had no changes
    by itself a WRITE doesn't cancel a DELETE, the value can still be different!
- (4, 5) a list is queued as DELETE if the merged index has the list id as
   a tombstone and it exists in the the local index
  - (5) always overwrite any WRITE queue entry with a DELETE
- in all other cases the queue is not to be touched!
*/

function getListIdFromConfigFilename (filename) {
  return filename.replace(getFilename('config', ''), '')
}

export async function prepareForImport (blob) {
  const zip = new JSZip()
  await zip.loadAsync(blob)

  const indexFile = zip.file(getFilename('index', 'list'))
  const hasIndex = indexFile != null
  const localIndex = await db.get('index', 'list')

  let newIndex
  let mergedListIdsToTry = []

  if (hasIndex) {
    newIndex = fromFile(await indexFile.async('string'), 'index')
  } else {
    newIndex = lm.clone(localIndex)

    const configFilenamePattern = new RegExp(
      PATTERNS.REGEX_LIST_ID_STRING.replace('$', '') +
      getFilename('config', '').replaceAll('.', '\\.')
    )

    zip.forEach(function (_, file) {
      if (file.dir || !configFilenamePattern.test(file.name)) {
        return
      }

      const listId = getListIdFromConfigFilename(file.name)

      if (lm.has(localIndex, listId)) {
        mergedListIdsToTry.push(listId)
      } else {
        lm.set(newIndex, listId, { id: listId })
        lm.push(newIndex, listId)
      }
    })
  }

  const mergeDetails = lm.mergeForDetails(newIndex, localIndex, { includeRecoverOperation: true })
  const operations = Object.entries(mergeDetails.operations.a)

  console.log('localFileImport/importByActions original operations', mergeDetails.operations)
  console.log('localFileImport/importByActions original index', newIndex, localIndex, mergeDetails.result)

  if (hasIndex) {
    mergedListIdsToTry = lm.keys(newIndex)
      .filter((listId) => !helper.hasKey(mergeDetails.operations.a, listId))
  }

  // Add all the other listId keys that don't have a top level
  // change but may have their contents merged...
  for (const listId of mergedListIdsToTry) {
    const operation = lm.has(mergeDetails.result, listId) ? 'TRY_MERGE' : 'TRY_HIDDEN_MERGE'
    operations.push([listId, operation])
  }

  const actions = operations
    .map(([listId, operation]) => processListIdToAction(listId, operation, zip))

  return { hasIndex, mergedIndex: mergeDetails.result, actions }
}

function processListIdToAction (listId, operation, zip) {
  if (operation === lm.OPERATIONS.REMOVE) {
    return { listId, operation, skipReasons: [] }
  }

  const skipReasons = []

  if (zip.file(getFilename('config', listId)) == null) {
    skipReasons.push('CONFIG_NOT_FOUND')
  }

  if (zip.file(getFilename('cards', listId)) == null) {
    skipReasons.push('CARDS_NOT_FOUND')
  }

  if (!PATTERNS.REGEX_LIST_ID.test(listId)) {
    skipReasons.push('ID_NOT_MATCHING_PATTERN')
  }

  return { listId, operation, skipReasons }
}

export async function importByActions (blob, actions, newIndex) {
  const zip = new JSZip()
  await zip.loadAsync(blob)

  console.log('localFileImport/importByActions actions', actions)

  // Before the transactions we first read all files from the zip file
  for (const action of actions) {
    if (action.operation === lm.OPERATIONS.REMOVE || action.skipReasons.length > 0) {
      continue
    }

    const configFile = zip.file(getFilename('config', action.listId))
    const cardsFile = zip.file(getFilename('cards', action.listId))

    action.config = fromFile(await configFile.async('string'), 'config')
    action.cards = fromFile(await cardsFile.async('string'), 'cards')
  }

  // All writing happens in one indedexeddb transaction one after the other
  const tx = db.transaction(['index', 'config', 'queue', 'cards'], 'readwrite')

  for (const action of actions) {
    if (action.skipReasons.length > 0) {
      console.log('localFileImport/importByActions skipped', action.listId, action.skipReasons)
      continue
    }

    if (action.operation === lm.OPERATIONS.REMOVE) {
      console.log('localFileImport/importByActions operation REMOVE', action.listId)
      // Ensure that REMOVED lists always have a DELETE queue entry
      await queueDeleteOnRemote(tx, 'config', action.listId)
      await queueDeleteOnRemote(tx, 'cards', action.listId)

      continue
    }

    const configChanged = await tryMergeAndWrite(tx, 'config', action.listId, action.config)
    const cardsChanged = await tryMergeAndWrite(tx, 'cards', action.listId, action.cards)

    if (action.operation === 'TRY_HIDDEN_MERGE') {
      // Don't change the queue, it is correct as it is
      continue
    }

    // Ensure that a RECOVERED config always has a WRITE queue entry regardless of whether the syncFile had changes
    if (configChanged || action.operation === lm.OPERATIONS.RECOVER) {
      console.log('localFileImport/importByActions queue WRITE config', action.listId)
      await queueWriteToRemote(tx, 'config', action.listId)
    }

    // Ensure that RECOVERED cards always has a WRITE queue entry regardless of whether the syncFile had changes
    if (cardsChanged || action.operation === lm.OPERATIONS.RECOVER) {
      console.log('localFileImport/importByActions queue WRITE cards', action.listId)
      await queueWriteToRemote(tx, 'cards', action.listId)
    }
  }

  console.log('localFileImport/importByActions write newIndex to store')
  // TODO: replace with syncFile/tryMergeAndWrite? or check isIdentical?
  await writeToStore(tx, 'index', newIndex, 'list')
}

async function tryMergeAndWrite (tx, type, listname, imported) {
  console.log('localFileImport/tryMergeAndWrite', type, listname)

  const domainStore = tx.objectStore(type)
  const localProperty = await domainStore.get(listname)
  const existsLocally = helper.isObject(localProperty)

  let changed = false
  let property = imported

  if (existsLocally) {
    const mergeDetails = lm.mergeForDetails(imported, localProperty)
    property = mergeDetails.result
    changed = !mergeDetails.isIdentical
  }

  if (changed || !existsLocally) {
    console.log('localFileImport/tryMergeAndWrite > writeToStore:', type, listname, `changed: ${changed}`, `existsLocally: ${existsLocally}`)
    if (domainStore.keyPath == null) {
      await domainStore.put(property, listname)
    } else {
      await domainStore.put(property)
    }

    return true
  }

  return false
}

// async function syncFileOld (tx, type, listname, importedProperty) {
//   console.log('localFileImport/syncFile', type, listname)

//   const localProperty = await tx.objectStore(type).get(listname)
//   const existsLocally = helper.isObject(localProperty)

//   let changed = false
//   let newProperty

//   if (existsLocally) {
//     try {
//       newProperty = lm.mergeOrFail(importedProperty, localProperty)
//       changed = true
//     } catch (error) {
//       if (!(error instanceof lm.MergeResultIdenticalError)) {
//         throw error
//       }
//     }
//   } else {
//     newProperty = importedProperty
//   }

//   if (changed || !existsLocally) {
//     console.log('localFileImport/syncFile > writeToStore:', type, listname, `changed: ${changed}`, `existsLocally: ${existsLocally}`)
//     await writeToStore(tx, type, newProperty, listname)
//   }
// }
