const STORAGE_KEY_PREFIX = process.env.VUE_APP_NAME + '.'

export function get ({ key, fallback }) {
  const item = window.localStorage.getItem(STORAGE_KEY_PREFIX + key)

  if (item == null) {
    return fallback
  }

  try {
    return JSON.parse(item)
  } catch (error) {
    destroy({ key })
    console.warn(`localStorage "${key}" could not have been parsed to JSON and was removed`, error)

    return fallback
  }
}

export function all ({ key }) {
  return get({ key, fallback: [] })
}

export function set ({ key, value }) {
  window.localStorage.setItem(STORAGE_KEY_PREFIX + key, JSON.stringify(value))
}

export function push ({ key, item }) {
  const array = all({ key })
  array.push(item)

  window.localStorage.setItem(STORAGE_KEY_PREFIX + key, JSON.stringify(array))
}

export function destroy ({ key }) {
  window.localStorage.removeItem(STORAGE_KEY_PREFIX + key)
}
