<script>
import * as db from '@/functions/database'

export default {
  name: 'SystemMenu',

  props: {
    dialogId: {
      type: String,
      required: true
    }
  },

  computed: {
    isSomeDatabaseOpen () {
      return db.isSomeDatabaseOpen()
    }
  },

  methods: {
    openLogin () {
      this.$router.replace({ name: 'login' })
      this.closeMenu()
    },

    openAboutDialog () {
      this.$store.dispatch('dialog/create', { componentName: 'About' })
    },

    goTo (routeName) {
      this.$router.push({ name: routeName })
      this.closeMenu()
    },

    closeMenu () {
      this.$store.commit('dialog/clear')
    }
  }
}
</script>

<template>
  <BaseGlobalDialog :dialog-id="dialogId">
    <h3>System Menu</h3>

    <div slot="buttons">
      <button class="button full" @click="openAboutDialog">
        About
      </button>

      <button v-if="isSomeDatabaseOpen" class="button full" @click="goTo('removedLists')">
        Removed Lists
      </button>

      <button v-if="isSomeDatabaseOpen" class="button full" @click="goTo('importExport')">
        Import & Export
      </button>

      <button class="button full" @click="goTo('userGuide')">
        User Guide
      </button>

      <button v-if="isSomeDatabaseOpen" class="button full" @click="openLogin()">
        Login
      </button>

      <button v-if="$route.name !== 'databases'" class="button full" @click="goTo('databases')">
        Exit & Choose database...
      </button>

      <div style="height: 20px"></div>
    </div>
  </BaseGlobalDialog>
</template>
