<template>
  <div id="app" :class="{ 'input-focused': isInputFocused }">
    <transition :name="transitionName">
      <router-view v-if="ready" :key="$route.fullPath" />
    </transition>

    <Alert />

    <component
      v-for="dialog in openGlobalDialogs"
      :dialog-id="dialog.id"
      :key="dialog.id"
      :is="dialog.component"
    />

    <div v-if="!ready" class="lonely-centered">
      <div><BaseSpinner /></div>

      <br><br>

      <div v-for="message in startupMessages" :key="message">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import DialogSystemMenu from '@/dialog/system-menu'
import Alert from '@/components/alert'
import { evaluatorSetup } from '@/functions/evaluator'
import * as database from '@/functions/database'

export default {
  name: 'App',

  components: {
    DialogSystemMenu,
    Alert
  },

  data () {
    return {
      ready: false,
      startupMessages: [],
      isInputFocused: false,
      transitionName: null,
      waitingServiceWorker: null
    }
  },

  async created () {
    try {
      const lastOpenedDatabase = database.lastOpenedDatabase()

      if (lastOpenedDatabase == null) {
        if (this.$route.name !== 'databases') {
          this.$router.push({ name: 'databases' })
        }
      } else {
        this.logStartupMessages('Opening indexed database...')
        await database.openAndMigrateDatabase(lastOpenedDatabase)
      }

      this.logStartupMessages('Setting up javascript evaluator...')
      await evaluatorSetup()

      this.logStartupMessages('App is ready!')
      this.ready = true
    } catch (error) {
      this.logStartupMessages(error)
      this.logStartupMessages('There was an error, the app couldn\'t start successfully.')
    }
  },

  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length

      this.transitionName = toDepth === fromDepth
        ? 'slide-up'
        : (toDepth < fromDepth)
          ? (this.$helper.isIphone()) ? '' : 'slide-right'
          : 'slide-left'
    }
  },

  computed: {
    openGlobalDialogs () {
      return this.$store.getters['dialog/openGlobalComponents']
    }
  },

  methods: {
    setInputFocused (event) {
      const tagNames = ['TEXTAREA', 'INPUT']
      if (tagNames.includes(event.target.tagName)) {
        this.isInputFocused = event.type === 'focusin'
      }
    },

    logStartupMessages (message) {
      this.startupMessages.push(message)
      console.info(message)
    }
  },

  mounted () {
    if ('ontouchstart' in window) {
      document.addEventListener('focusin', this.setInputFocused)
      document.addEventListener('focusout', this.setInputFocused)
    }
  }
}
</script>

<style>
.view.slide-up-enter-active,
.view.slide-up-leave-active,
.view.slide-left-enter-active,
.view.slide-right-enter-active,
.view.slide-left-leave-active,
.view.slide-right-leave-active {
    transition: transform 150ms cubic-bezier(.3,.6,.4,1), opacity 150ms ease;
    background-color: #262626;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.view.slide-up-enter-to,
.view.slide-right-enter-to,
.view.slide-left-enter-to {
    transform: translateX(0);
}

.view.slide-up-leave-to {
    transform: translateY(-30%);
    opacity: 0.5;
}

.view.slide-up-enter {
    transform: translateY(100%);
}

.view.slide-right-leave-to {
    transform: translateX(50%);
}

.view.slide-right-enter {
    transform: translateX(-100%);
}

.view.slide-left-enter {
    transform: translateX(100%);
}

.view.slide-left-leave-to {
    transform: translateX(-50%);
}
</style>
