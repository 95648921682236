<script>
import * as storage from '@/functions/storage'

export default {
  data () {
    return {
      removedLists: []
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    async load () {
      this.removedLists = await storage.getRemovedListIds()
    },

    recoverList (listId) {
      storage.recoverList({ listId })
      this.load()
    },

    deleteList (listId) {
      storage.deleteList({ listId })
      this.load()
    }
  }
}
</script>

<template>
  <BaseViewTemplate>
    <div slot="header">
      <h5>Removed Lists</h5>

      <button class="button button--small" @click="$router.push({ name: 'start' })">
        Close
      </button>
    </div>

    <p style="margin-top: 15px">
      Lists are automatically deleted 6 days after their removal.
    </p>

    <div class="list" v-for="list in removedLists" :key="list.id">
      <strong>{{ list.id }}</strong>, removed at {{ list.removedAt }}

      <div class="button-row">
        <button class="button destructive" @click="deleteList(list.id)">
          Delete
        </button>
        <button class="button" @click="recoverList(list.id)">
          Recover
        </button>
      </div>
    </div>
  </BaseViewTemplate>
</template>

<style scoped>
.list {
  margin: 20px 10px;
}

.button.destructive {
  width: 100px;
}
</style>
