const DURATION_IN_SECONDS = 5

const state = {
  type: '',
  text: '',
  display: false
}

const mutations = {
  show (state) {
    state.display = true
  },

  hide (state) {
    state.display = false
  },

  set (state, { text, type }) {
    state.text = text || 'No text'
    state.type = type || 'log'
  }
}

const actions = {
  push ({ commit }, payload) {
    commit('set', payload)
    commit('show')

    let duration = DURATION_IN_SECONDS
    const intervalInMilliseconds = 1000

    const interval = setInterval(() => {
      duration--

      if (duration < 0) {
        commit('hide')
        clearInterval(interval)
      }
    }, intervalInMilliseconds)
  },

  log ({ dispatch }, payload) {
    payload.type = 'log'
    dispatch('push', payload)
  },

  error ({ dispatch }, payload) {
    payload.type = 'error'
    dispatch('push', payload)
  },

  warn ({ dispatch }, payload) {
    payload.type = 'warn'
    dispatch('push', payload)
  },

  success ({ dispatch }, payload) {
    payload.type = 'success'
    dispatch('push', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
