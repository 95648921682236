/*
 * https://stackoverflow.com/a/27747377
 * Modified to use base36 instead of hex
 */
function generateRandomId (length = 10) {
  const array = new Uint16Array(length / 2)
  window.crypto.getRandomValues(array)

  const decTo36 = decimal => ('0' + decimal.toString(36)).substr(-2)
  return Array.from(array, decTo36).join('')
}

/*
 * inspired by the hashCode function in Java
 * https://stackoverflow.com/a/33647870
 */
function checksum (string) {
  if (typeof string !== 'string') {
    string = string.toString()
  }

  let hash = 0
  let index = 0

  while (index < string.length) {
    const char = string.charCodeAt(index++) << 0
    hash = ((hash << 5) - hash) + char
  }

  return ((hash + 2147483647) + 1).toString(16)
}

function cssColorFromHue (hue) {
  const saturation = '60%'
  const lightness = '60%'
  return `hsl(${hue}, ${saturation}, ${lightness})`
}

function cssBasicHueGradient (hue) {
  const left = cssColorFromHue(hue)
  const right = cssColorFromHue(hue - 20)
  return `linear-gradient(to right, ${left} 50%, ${right} 100%)`
}

function getRandomInt (min, max) {
  // The maximum is exclusive and the minimum is inclusive
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

function isObject (object) {
  return Object.prototype.toString.call(object) === '[object Object]'
}

function hasKey (object, key) {
  return Object.prototype.hasOwnProperty.call(object, key)
}

function isIphone () {
  return /iPhone/.test(navigator.platform)
}

function toBase36 (value) {
  return value.toString(36)
}

function fromBase36 (value) {
  return parseInt(value, 36)
}

function sleep (milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function uniquenizeArray (array) {
  const seen = {}

  return array.filter(item => {
    const key = JSON.stringify(item)

    if (({}).hasOwnProperty.call(seen, key)) {
      return false
    }

    seen[key] = true
    return true
  })
}

function basicDateFormat (date) {
  if (date instanceof Date) {
    date = date.toISOString()
  }

  return date.replace(/\.\d{3}Z/, '').replace('T', ' ')
}

export default {
  getRandomInt,
  cssColorFromHue,
  cssBasicHueGradient,
  checksum,
  generateRandomId,
  isIphone,
  toBase36,
  fromBase36,
  sleep,
  uniquenizeArray,
  basicDateFormat,
  isObject,
  hasKey
}
