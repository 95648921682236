<template>
  <BaseViewTemplate>
    <div class="last-update">
      {{ lastUpdate.buildDate }}<br />
      {{ lastUpdate.version }}
    </div>

    <div slot="header">
      <img class="logo" :src="logoUrl" />

      <div class="button-row button-row--inline start-header-buttons">
        <button class="button"
                @click="openSystemMenu()"
                :disabled="isEditMode">
          …
        </button>

        <button class="button"
                :disabled="isLoading || isEditMode"
                @click="$router.push({ name: 'listCreate' })">
          +
        </button>

        <Sync @done="load()" :disabled="isEditMode" />

        <button class="button"
                style="min-width: 60px;"
                :class="{ primary: isEditMode }"
                @click="toggleEditMode()">
          <span v-show="isEditMode">Done</span>
          <span v-show="!isEditMode">Edit</span>
        </button>
      </div>
    </div>

    <div class="lonely-centered"
         v-if="isLoading">
      <BaseSpinner />
      <h4>Loading...</h4>
    </div>

    <div v-else>
      <p style="padding-top: 8px">
        <label v-show="isEditMode">
          <input type="checkbox" v-model="showHiddenLists">
          <span>Keep hidden lists 👀 visible outside of editmode</span>
        </label>
      </p>

      <div class="lists">
         <draggable :value="filteredLists"
                   @sort="onSort"
                   :delay="100"
                   :animation="150"
                   :disabled="!isEditMode">
          <ListPreview v-for="list in filteredLists"
                       :key="list.id"
                       :list="configs.get(list.id)"
                       :draggable="isEditMode"
                       :minimize="isEditMode"
                       @click="openList(list.id)"
                       tabindex="0"/>
         </draggable>
      </div>
    </div>
  </BaseViewTemplate>
</template>

<script>
import lm from 'legible-mergeable'
import draggable from 'vuedraggable'
import ListPreview from '@/components/list-preview'
import Sync from '@/components/sync'
import { LIST_FLAGS } from '@/constants'
import { db } from '@/functions/database'
import { updateListIndex } from '@/functions/storage'

export default {
  name: 'Start',

  data () {
    return {
      isEditMode: false,
      isLoading: true,
      listIndex: null,
      configs: null,
      showHiddenLists: false,
      somethingWasSorted: false
    }
  },

  mounted () {
    this.load()
  },

  computed: {
    lastUpdate () {
      return this.$store.getters['serviceWorker/lastUpdate']
    },

    logoUrl () {
      return process.env.BASE_URL + 'icons/android-chrome-192x192.png'
    },

    filteredLists () {
      return lm.sorted(this.listIndex).filter(list => {
        return this.showHiddenLists ||
          this.isEditMode ||
          !this.configs.get(list.id).flags.includes(LIST_FLAGS.HIDDEN)
      })
    }
  },

  methods: {
    onSort (change) {
      const id = this.filteredLists[change.oldIndex].id

      const offsetIndex = (change.oldIndex > change.newIndex)
        ? change.newIndex - 1
        : change.newIndex

      const afterId = offsetIndex === -1
        ? null
        : this.filteredLists[offsetIndex].id

      lm.move(this.listIndex, id, afterId)
      this.somethingWasSorted = true
    },

    async toggleEditMode () {
      if (this.isEditMode && this.somethingWasSorted) {
        this.isLoading = true
        this.somethingWasSorted = false

        this.listIndex = await updateListIndex({ newListIndex: this.listIndex })

        await this.loadConfigs()
      }

      this.isEditMode = !this.isEditMode
      this.isLoading = false
    },

    openList (listId) {
      if (!this.isEditMode) {
        this.$router.push({ name: 'listIndex', params: { listId } })
      }
    },

    openSystemMenu () {
      this.$store.dispatch('dialog/create', { componentName: 'SystemMenu' })
    },

    async load () {
      this.isLoading = true

      this.listIndex = await db.get('index', 'list')
      await this.loadConfigs()

      this.isLoading = false
    },

    async loadConfigs () {
      this.configs = new Map()

      for (const list of lm.filter(this.listIndex)) {
        const config = await db.get('config', list.id)

        if (list) {
          this.configs.set(list.id, config)
        }
      }
    }
  },

  components: {
    draggable,
    ListPreview,
    Sync
  }
}
</script>

<style scoped>
.lists {
  padding: 10px 0;
  margin: 0 10px;
}

.last-update {
  position: fixed;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  font-size: 11px;
  padding: 0 0 5px 10px;
}

.start-header-buttons > button {
  padding: 8px 16px;
}

.logo {
  width: 36px;
  margin: 8px 0;
}
</style>
