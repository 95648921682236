const presets = []

presets.push({
  name: 'Memo',
  fields: `return {
  title: {
    label: 'Title',
    type: 'text',
    required: true
  },
  content: {
    label: 'Content',
    type: 'long'
  }
}`,
  header: `return {
  primary: args.card.title,
  secondary: args.card.content?.slice(0, 20) + '...'
}`,
  arrangeCards: 'return {}'
})

presets.push({
  name: 'Todo',
  fields: `return {
  title: {
    label: 'Title',
    type: 'text',
    required: true
  },
  status: {
    label: 'Status',
    type: 'select',
    options: ['todo', 'doing', 'done'],
    default: 'todo',
    _itemCheckbox: 'done'
  },
  created: {
    label: 'Created',
    type: 'date',
    default: (new Date()).toISOString().slice(0, 10)
  },
  description: {
    label: 'Description',
    type: 'long'
  }
}`,
  header: `return {
  primary: args.card.title,
  secondary: 'created at ' + args.card.created ?? ''
}`,
  arrangeCards: `return {
  orderBy: 'created',
  descendingOrder: true,
  groupBy: 'status',
  collapsedGroups: ['done']
}`
})

presets.push({
  name: 'Tracker',
  fields: `return {
  subject: {
    label: 'Subject',
    type: 'text',
    required: true
  },
  type: {
    label: 'Type',
    type: 'select',
    options: ['A', 'B', 'C']
  },
  date: {
    label: 'Date',
    type: 'date',
    default: (new Date()).toISOString().slice(0, 10)
  }
}`,
  header: `return {
  primary: args.card.type +': '+ args.card.subject,
  secondary: args.card.date
}`,
  arrangeCards: `return {
  orderBy: 'date',
  descendingOrder: true
}`
})

presets.push({
  name: 'Custom',
  fields: `return {
  // your fields here
}`,
  header: `return {
  primary: args.card.id,
  secondary: parseInt(args.card.id, 36)
}`,
  arrangeCards: 'return {}'
})

export default presets
