<script>
import { marked } from 'marked'

import welcome from '@/../guide/welcome.md'
import listConfig from '@/../guide/list-config.md'
import webdav from '@/../guide/webdav.md'
import synchronisation from '@/../guide/synchronisation.md'
import ideas from '@/../guide/ideas.md'
import evil from '@/../guide/evil.md'

export default {
  data () {
    return {
      guides: [
        welcome,
        listConfig,
        webdav,
        synchronisation,
        ideas,
        evil
      ]
    }
  },

  methods: {
    toMarkdown (guide) {
      return marked(guide)
    }
  }
}
</script>

<template>
  <BaseViewTemplate>
    <div slot="header">
      <h5>User Guide</h5>

      <button class="button button--small" @click="$router.back()">
        Close
      </button>
    </div>

    <div class="markdown">
      <div v-for="(guide, index) in guides" :key="index">
        <div v-html="toMarkdown(guide)" />
        <hr />
      </div>
    </div>
  </BaseViewTemplate>
</template>
