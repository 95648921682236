<template>
  <div class="spinner" :style="{ borderColor: color }"></div>
</template>

<script>
export default {
  name: 'BaseSpinner',

  props: {
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style>
@keyframes fade{
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.spinner {
  margin: 0 .5em;
  font-size: 13px;
  display: inline;
}

.spinner:before,
.spinner:after {
  content: '';
  display: inline-block;
  border-radius: 100%;
  background-color: transparent;
  width: 0.5em;
  height: 0.5em;
  border: 0.2em solid;
  border-color: inherit;
  margin: 0 0.5em;
}

.spinner:before {
  animation: fade 2s ease infinite;
}

.spinner:after {
  animation: fade 2s ease infinite;
  animation-delay: -1s;
}
</style>
