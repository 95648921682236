<template>
  <BaseViewTemplate :backgroundHeaderFooter="gradient">
    <div slot="header">
      <h5>Create New List</h5>
    </div>

    <div class="form">
      <label>
        <span class="title">Name</span>
        <input type="text"
               @input="computeId()"
               v-model="list.name">
        <p class="text text-warning"
           v-if="!isNameValid">
          A list name is required
        </p>
      </label>

      <label>
        <span class="title">Id</span>
        <input type="text" v-model="list.id">
        <p class="text">
          The list id is also the filename on the server and can't be changed after creation.
        </p>
        <p class="text text-warning" v-show="!isIdValid">
          A list id is required. Allowed are the characters a-z (upper- and lowercase), numbers, dash and underscore.
        </p>
      </label>

      <label>
        <span class="title" @click="randomizeColor">
          Color (Hue {{ list.hue }}°)
        </span>
        <FieldHueColorRange v-model="list.hue" @gradient="gradient = $event" />
      </label>

      <label>
        <span class="title">Flags</span>
        <FieldMultiselect v-model="list.flags"
                          key="flags"
                          :creatable="true"
                          :options="availableFlags" />
      </label>

      <label>
        <span class="title">Preset</span>
        <select v-model="preset"
                @change="selectPreset()">
          <option :value="null"
                  disabled>Select a preset</option>
          <option v-for="preset of listPresets"
                  :value="preset"
                  :key="preset.name">
            {{ preset.name }}
          </option>
        </select>

        <p class="text text-warning"
              v-if="!isPresetSelected">
          A preset for the list scheme is required
        </p>
      </label>

      <label v-if="isCodeVisible">
        <span class="title">Field Definitions</span>
        <textarea v-model="list.fields"
                  v-autogrow
                  class="code">
        </textarea>
      </label>

      <label v-if="isCodeVisible">
        <span class="title">Header</span>
        <textarea v-model="list.header"
                  v-autogrow
                  class="code">
        </textarea>
      </label>

      <label v-if="isCodeVisible">
        <span class="title">Arrange Cards</span>
        <textarea v-model="list.arrangeCards" v-autogrow class="code" />
      </label>

      <label v-if="isCodeVisible">
        <p><code>prepareSummaryScript(args) {</code></p>
        <p><code>// args is an object with { cards, list }</code></p>
        <textarea v-model="list.prepareSummaryScript" v-autogrow class="code" placeholder="return { foo: 'bar' }" />
        <p><code>}</code></p>
      </label>

      <label v-if="isCodeVisible">
        <span class="title">Summary Markup</span>
        <p class="form-field__info">Use the return value of script above with mustache templating. Gets parsed as markdown.</p>
        <textarea v-model="list.summaryMarkup" v-autogrow class="code" :placeholder="'Value of *foo*: `{{foo}}`'" />
      </label>

      <p v-if="isButtonCodeVisible">
        <button class="button full"
                @click="isCodeVisible = true">
          Edit list scheme and field definitions
        </button>
      </p>
    </div>

    <div slot="footer">
      <button class="button" @click="$router.back()">◁</button>

      <button class="button primary"
              :disabled="disableCreate"
              @click="create()">
        Create
      </button>
    </div>
  </BaseViewTemplate>
</template>

<script>
import { LIST_FLAGS, LIST_PRESETS, PATTERNS } from '@/constants'
import autogrow from '@/directives/autogrow'
import FieldMultiselect from '@/components/field-multiselect'
import FieldHueColorRange from '@/components/field-hue-color-range'
import { createList } from '@/functions/storage'

export default {
  name: 'ListConfigCreate',

  data () {
    return {
      list: {
        name: null,
        id: null,
        flags: [],
        hue: null,
        header: null,
        arrangeCards: null,
        prepareSummaryScript: null,
        summaryMarkup: null,
        fields: null,
        created: null
      },

      gradient: '',
      preset: null,
      isCodeVisible: false
    }
  },

  mounted () {
    this.randomizeColor()
  },

  methods: {
    computeId () {
      if (this.list.name == null) {
        return
      }

      this.list.id = this.list.name
        .toLowerCase()
        .replace(PATTERNS.REGEX_LIST_ID_NEGATIV, '')
        .trim()
        .replace(/\s/g, '-')
    },

    selectPreset () {
      const isCustomPreset = this.preset.name === 'Custom'
      this.isCodeVisible = isCustomPreset

      this.list.fields = this.preset.fields
      this.list.header = this.preset.header
      this.list.arrangeCards = this.preset.arrangeCards
      this.list.summaryMarkup = this.preset.summaryMarkup || ''
      this.list.prepareSummaryScript = this.preset.prepareSummaryScript || ''
    },

    async create () {
      const config = await createList({ config: this.list })

      this.$router.replace({ name: 'listIndex', params: { listId: config.id } })
    },

    randomizeColor () {
      this.list.hue = this.$helper.getRandomInt(0, 360)
    }
  },

  computed: {
    isIdValid () {
      return PATTERNS.REGEX_LIST_ID.test(this.list.id)
    },

    isButtonCodeVisible () {
      return !this.isCodeVisible && !!this.preset
    },

    isPresetSelected () {
      return !!this.preset
    },

    isNameValid () {
      return !!this.list && !!this.list.name && this.list.name.trim() !== ''
    },

    listPresets () {
      return LIST_PRESETS
    },

    availableFlags () {
      return Object.values(LIST_FLAGS).filter(flag => flag[0] !== '_')
    },

    disableCreate () {
      return !this.isNameValid || !this.isPresetSelected || !this.isIdValid
    }
  },

  directives: {
    autogrow
  },

  components: {
    FieldMultiselect,
    FieldHueColorRange
  }
}
</script>
