<template>
  <BaseViewTemplate class="view-list-index" :backgroundHeaderFooter="gradient">
    <div slot="header">
      <h5>{{ title }}</h5>

      <Sync v-if="isRemoteEnabled" @done="load()" />
    </div>

    <div class="lonely-centered" v-if="isLoading" key="loading">
      <BaseSpinner />
      <h4>Loading...</h4>
    </div>

    <BaseMessage v-bind="error" v-if="error" />

    <div v-if="!isLoading" key="loaded" class="sections">
      <ListSummary
        :cards="cards"
        :list="list"
        @error="error = $event"
      />

      <div v-for="(cards, sectionKey) in cardsBySections" :key="sectionKey">
        <div v-show="sectionKey !== 'undefined' || sectionSize !== 1"
             class="section-title"
             @click="toggleGroupCollapse(sectionKey)">
          <span>{{ sectionKey || '...' }}</span>

          <span v-show="isGroupCollapsed(sectionKey)">
            {{ cards.length }} cards collapsed
          </span>
        </div>

        <div v-if="!isGroupCollapsed(sectionKey)">
          <CardItem v-for="card in cards"
                    @click="openCard"
                    @update="persistCard"
                    :key="'card-' + card.id"
                    :checkbox-field="checkboxField"
                    :card="card"
                    :list="list" />
          <p v-if="!cards || cards.length === 0">No cards...</p>
        </div>
      </div>
    </div>

    <p class="small" v-if="!isLoading && cards.length > 1">{{ cards.length }} cards</p>

    <div slot="footer">
      <div class="button-row button-row--inline">
        <button class="button" @click="$router.back()">◁</button>

        <button class="button" @click="openConfig()">
          Config
        </button>

        <button class="button text-icon" @click="openArrangeCardsMenu()">
          ⌕
        </button>
      </div>

      <button class="button" :disabled="disableAdd" @click="addCard()">
        Add Card
      </button>
    </div>

    <ArrangeCardsMenu
      :visible="isArrangeCardsMenuVisible"
      :card-arrangements="arrangement"
      :field-key-options="Object.keys(definedFields)"
      @close="isArrangeCardsMenuVisible = false"
      @apply="applyArrangement"
    />
  </BaseViewTemplate>
</template>

<script>
import lm from 'legible-mergeable'
import * as storage from '@/functions/storage'
import CardItem from '@/components/card-item.vue'
import Sync from '@/components/sync'
import ListSummary from '@/components/list-summary'
import ArrangeCardsMenu from '@/dialog/arrange-cards-menu'
import { LIST_FLAGS } from '@/constants'
import { db } from '@/functions/database'
import { evaluator } from '@/functions/evaluator'
import { groupCards, orderCards } from '@/functions/arrange-cards'

export default {
  name: 'ListIndex',

  props: {
    listId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      list: null,
      cards: [],
      isLoading: true,
      error: null,
      isArrangeCardsMenuVisible: false,
      arrangement: {
        orderBy: null,
        descendingOrder: false,
        groupBy: null,
        collapsedGroups: []
      },
      cardsBySections: null,
      definedFields: {}
    }
  },

  computed: {
    title () {
      return (this.list) ? this.list.name : this.listId
    },

    isRemoteEnabled () {
      return !this.list?.flags.includes(LIST_FLAGS.NO_REMOTE)
    },

    disableAdd () {
      return this.list?.flags.includes(LIST_FLAGS._NO_CARDS_ADD)
    },

    gradient () {
      return !this.isLoading ? this.$helper.cssBasicHueGradient(this.list.hue) : null
    },

    sectionSize () {
      return Object.keys(this.cardsBySections).length
    },

    checkboxField () {
      for (const fieldKey of Object.keys(this.definedFields)) {
        const field = this.definedFields[fieldKey]

        if (this.$helper.hasKey(field, '_itemCheckbox')) {
          return {
            fieldKey,
            trueValue: field._itemCheckbox || true,
            falseValue: field.default || false
          }
        }
      }

      return null
    }
  },

  mounted () {
    this.$emit('mounted', this)

    this.load()
  },

  watch: {
    '$route' () {
      this.list = null
      this.load()
    }
  },

  methods: {
    openCard (card) {
      this.$router.push({
        name: 'cardDetail',
        params: { listId: this.listId, cardId: card.id }
      })
    },

    async openArrangeCardsMenu () {
      this.isArrangeCardsMenuVisible = true
    },

    async persistCard (card) {
      await storage.updateCard({
        listId: this.listId,
        newCard: card
      })

      await this.computeArrangeCardsScript()
    },

    async load () {
      this.isLoading = true

      const tx = db.transaction(['config', 'cards'])
      this.list = await tx.objectStore('config').get(this.listId)
      const cardsMergeable = await tx.objectStore('cards').get(this.listId)

      this.cards = cardsMergeable ? lm.filter(cardsMergeable) : []
      await this.computeFieldsScript()
      await this.computeArrangeCardsScript()

      this.isLoading = false
    },

    applyArrangement (arrangement) {
      if (!this.cardsBySections) {
        this.cardsBySections = { undefined: { cards: this.cards } }
      }

      this.arrangement = { ...this.arrangement, ...arrangement }

      const cards = orderCards(arrangement, [...this.cards])
      this.cardsBySections = groupCards(arrangement, cards, this.definedFields)
    },

    async computeFieldsScript () {
      try {
        this.definedFields = await evaluator(this.list.fields)
      } catch (error) {
        this.error = {
          info: 'fieldOptions:' + error.location,
          type: 'error',
          message: error.message
        }
      }
    },

    async computeArrangeCardsScript () {
      try {
        const result = (this.list.arrangeCards)
          ? await evaluator(this.list.arrangeCards)
          : {}

        this.applyArrangement(result)
      } catch (error) {
        this.error = {
          info: 'arrangeCards:' + error.location,
          type: 'error',
          message: error.message
        }
      }
    },

    toggleGroupCollapse (sectionKey) {
      const index = this.arrangement.collapsedGroups.indexOf(sectionKey)

      if (index >= 0) {
        this.arrangement.collapsedGroups.splice(index, 1)
      } else {
        this.arrangement.collapsedGroups.push(sectionKey)
      }
    },

    isGroupCollapsed (sectionKey) {
      return this.arrangement.collapsedGroups.includes(sectionKey)
    },

    openConfig () {
      this.$router.push({ name: 'listConfig', params: { listId: this.listId } })
    },

    addCard () {
      this.$router.push({ name: 'cardAdd', params: { listId: this.listId } })
    }
  },

  components: {
    CardItem,
    Sync,
    ArrangeCardsMenu,
    ListSummary
  }
}
</script>

<style>
.view-list-index .sync-button {
  margin: 3px 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.view-list-index .sections {
  margin-top: 10px;
}

.view-list-index .sections .section-title {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 13px;
}
</style>
