import Vue from 'vue'

import BaseViewTemplate from './view-template'
import BaseDialog from './dialog'
import BaseGlobalDialog from './global-dialog'
import BaseSpinner from './spinner'
import BaseMessage from './message'

Vue.component('BaseViewTemplate', BaseViewTemplate)
Vue.component('BaseDialog', BaseDialog)
Vue.component('BaseGlobalDialog', BaseGlobalDialog)
Vue.component('BaseSpinner', BaseSpinner)
Vue.component('BaseMessage', BaseMessage)
