<template>
  <div class="list-item"
       :style="{ background }"
       @click="$emit('click')"
       @keyup.enter="$emit('click')">
    <div class="list-item__header">
      <h3 class="list-item__name">
        {{ list.name }}
      </h3>

      <div v-if="!minimize" :title="allFlags">
        {{ significantFlags }}
        <span v-if="!!size">
          {{ size }}
        </span>
      </div>

      <div v-show="draggable"
           class="list-item__drag-handle">
        ≡
      </div>
    </div>

    <div v-if="isShowWrapped && headers.length > 0"
         class="list-item__peak">
      <BaseSpinner v-if="headers.length == 0" />
      <span v-if="headers.length > 0">{{ headers.join(', ') }}</span>
    </div>
  </div>
</template>

<script>
import lm from 'legible-mergeable'
import { evaluator } from '@/functions/evaluator'
import { LIST_FLAGS } from '@/constants'
import { db } from '@/functions/database'

export default {
  props: {
    list: {
      required: true,
      type: Object
    },

    draggable: {
      type: Boolean
    },

    minimize: {
      type: Boolean
    }
  },

  data () {
    return {
      cards: null,
      headers: [],
      size: null
    }
  },

  computed: {
    isShowWrapped () {
      // TODO: remove EVERYWHERE this annoying question mark after the flag
      return !this.minimize && this.list.flags?.includes(LIST_FLAGS.WRAPABLE)
    },

    listId () {
      return this.list.id
    },

    background () {
      return this.$helper.cssBasicHueGradient(this.list.hue)
    },

    allFlags () {
      return this.list.flags.join('\n')
    },

    significantFlags () {
      const significant = {}
      significant[LIST_FLAGS.NO_REMOTE] = '💤'
      significant[LIST_FLAGS.HIDDEN] = '👀'

      return Object.keys(significant)
        .filter(flag => this.list.flags.includes(flag))
        .map(flag => significant[flag])
        .join(' ')
    }
  },

  mounted () {
    this.loadCards()
  },

  methods: {
    async setCardHeaders () {
      for (const cardId in this.cards) {
        evaluator(this.list.header, { card: this.cards[cardId] })
          .then(header => this.headers.push(header.primary))
          .catch(error => {
            this.headers = []
            this.headers.push(error.displayMessage)
          })
      }
    },

    async loadCards () {
      if (!this.isShowWrapped) {
        return
      }

      this.cards = lm.base(await db.get('cards', this.listId))
      this.size = lm.size(this.cards)

      this.setCardHeaders()
    }
  }
}
</script>

<style scoped>
.list-item {
  background-color: #1e1e1e;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  overflow: hidden;
}

.list-item__name {
  margin: 0;
}

.list-item__peak {
  padding: 10px;
  background-color: #0003;
}

.list-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.list-item__drag-handle {
  font-size: 25px;
  line-height: 0;
}
</style>
