import helper from '@/helper'
import SystemMenu from '@/dialog/system-menu'
import About from '@/dialog/about'

const registeredGlobalDialogs = {
  SystemMenu,
  About
}

const state = {
  visibleIds: [],
  globalComponentNames: {}
}

const getters = {
  isVisible: (state) => (id) => {
    return state.visibleIds.includes(id)
  },

  openGlobalComponents (state) {
    return state.visibleIds
      .filter((id) => helper.hasKey(state.globalComponentNames, id))
      .map((id) => {
        return {
          id,
          component: registeredGlobalDialogs[state.globalComponentNames[id]]
        }
      })
  },

  isInForeground: (state) => (id) => {
    return state.visibleIds.length > 0 && state.visibleIds.indexOf(id) === state.visibleIds.length - 1
  },

  level: (state) => (id) => {
    return state.visibleIds.length > 0 && state.visibleIds.indexOf(id)
  }
}

const mutations = {
  addGlobalComponent (state, { id, componentName }) {
    state.globalComponentNames[id] = componentName
  },

  show (state, { id }) {
    state.visibleIds.push(id)
  },

  close (state, { id }) {
    const index = state.visibleIds.indexOf(id)
    const count = (index > -1) ? 1 : 0
    state.visibleIds.splice(index, count)

    if (helper.hasKey(state.globalComponentNames, id)) {
      delete state.globalComponentNames[id]
    }
  },

  clear (state) {
    state.globalComponentNames = {}
    state.visibleIds.splice(0)
  }
}

const actions = {
  create ({ commit }, { componentName }) {
    // TODO: maybe check if componentName is in registeredGlobalDialogs?
    const id = helper.generateRandomId()
    commit('addGlobalComponent', { id, componentName })
    commit('show', { id })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
