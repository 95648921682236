<script>
import { db } from '@/functions/database'
import { runQueue, fetchAll } from '@/functions/remote-sync'
import { cleanupRemovedLists } from '@/functions/storage'

export default {
  name: 'Sync',

  props: {
    disabled: {
      type: Boolean
    }
  },

  data () {
    return {
      isLoading: false,
      queueSize: 0
    }
  },

  mounted () {
    this.resetQueueSize()
  },

  methods: {
    async resetQueueSize () {
      this.queueSize = await db.count('queue')
    },

    alertError (error) {
      console.error(error)
      this.$store.dispatch('alert/error', { text: error })
    },

    async sync () {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      if (this.queueSize > 0) {
        await runQueue().catch(this.alertError)
      } else {
        await fetchAll().catch(this.alertError)
        cleanupRemovedLists()
      }

      this.isLoading = false
      this.resetQueueSize()
      this.$emit('done')
    }
  }
}
</script>

<template>
  <button class="button sync-button" :disabled="disabled" @click="sync()">
    <BaseSpinner v-if="isLoading" />
    <span v-show="!isLoading && queueSize > 0">{{ queueSize }} </span>
    <span v-show="!isLoading">↻</span>
  </button>
</template>
