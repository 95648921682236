<script>
import LocalFileImport from '../components/local-file-import'
import LocalFileExport from '../components/local-file-export'

export default {
  components: {
    LocalFileImport,
    LocalFileExport
  }
}
</script>

<template>
  <BaseViewTemplate>
    <div slot="header">
      <h5>Local Import & Export</h5>

      <button class="button button--small" @click="$router.push({ name: 'start' })">
        Close
      </button>
    </div>

    <h2>Import</h2>

    <LocalFileImport />

    <hr />

    <h2>Export</h2>

    <LocalFileExport />
  </BaseViewTemplate>
</template>
