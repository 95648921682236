import helper from '@/helper'

const state = {
  serviceWorkerRegistration: null,
  isChecking: false,
  isUpdating: false
}

const getters = {
  isChecking (state) {
    return state.isChecking
  },

  hasWaiting (state) {
    return state.serviceWorkerRegistration && state.serviceWorkerRegistration.waiting != null
  },

  isUpdating (state) {
    return state.isUpdating
  },

  lastUpdate () {
    const date = helper.basicDateFormat(process.env.VUE_APP_BUILD_DATE) + ' UTC'
    return {
      buildDate: date,
      version: `${process.env.VUE_APP_VERSION}+${process.env.VUE_APP_LAST_COMMIT}`
    }
  }
}

const mutations = {
  checkForUpdate (state) {
    state.isChecking = true
    window.dispatchEvent(new Event('SERVICE_WORKER_CHECK_FOR_UPDATE'))
  },

  unsetChecking (state) {
    state.isChecking = false
  },

  updateAvailable (state, { registration }) {
    console.log('SERVICE_WORKER_UPDATE_AVAILABLE')
    state.isChecking = false
    state.serviceWorkerRegistration = registration
  },

  update (state) {
    if (state.serviceWorkerRegistration && state.serviceWorkerRegistration.waiting) {
      state.isUpdating = true
      state.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    state.serviceWorkerRegistration = null
  },

  reload (state) {
    if (state.isUpdating) {
      console.log('Reloading app to use updated cache...')
      window.location.reload()
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}

export function addEventListenerPlugin (store) {
  window.addEventListener('SERVICE_WORKER_UPDATE_AVAILABLE', (event) => {
    store.dispatch('alert/log', { text: 'App Update available' }, { root: true })
    store.commit('serviceWorker/updateAvailable', { registration: event.detail })
  })

  window.navigator.serviceWorker.addEventListener('controllerchange', () => {
    store.commit('serviceWorker/reload')
  })
}
