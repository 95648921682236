<template>
  <BaseViewTemplate :backgroundHeaderFooter="gradient">
    <div slot="header">
      <h5>{{ title }}</h5>
    </div>

    <BaseMessage v-bind="error" v-if="error" />

    <div v-if="isLoading"
         key="spinner"
         class="lonely-centered">
      <BaseSpinner />
    </div>

    <div v-if="!isLoading" key="content">
      <Field v-for="field in fields"
             :key="field.key"
             :field="field"
             v-model="cardProxy[field.key]" />
    </div>

    <div slot="footer">
      <div class="button-row button-row--inline">
        <button class="button" @click="$router.back()">◁</button>

        <button class="button" @click="showMoreDialog = !showMoreDialog">
          ...
        </button>
      </div>

      <button :disabled="disableSave" class="button primary" @click="persist()">
        Save
      </button>
    </div>

    <BaseDialog :visible="showMoreDialog" @close="showMoreDialog = false">
      <div slot="buttons">
        <button :disabled="disableDelete"
                class="button full destructive"
                @click="deleteCard()">
          Delete
        </button>

        <button class="button full" @click="showRawJson = true">
          Show Raw Json
        </button>

        <div style="height: 20px"></div>
      </div>
    </BaseDialog>

    <BaseDialog :visible="showRawJson" class="markdown" @close="showRawJson = false">
      <h3>Raw JSON</h3>
      <pre><code>{{ cardProxy }}</code></pre>
    </BaseDialog>
  </BaseViewTemplate>
</template>

<script>
import lm from 'legible-mergeable'

import { evaluator } from '@/functions/evaluator'
import { LIST_FLAGS } from '@/constants'
import Field from '@/components/field'
import { db } from '@/functions/database'
import * as storage from '@/functions/storage'

const INITIAL_DATA = () => ({
  list: {},
  fields: [],
  minimalLists: [],

  oldCardString: null,
  cardProxy: null,
  cardHeader: '',

  isLoading: true,
  error: null,
  showMoreDialog: false,
  showRawJson: false
})

export default {
  name: 'CardDetail',

  data: INITIAL_DATA,

  computed: {
    title () {
      if (!this.isLoading && this.list.name) {
        return this.list.name + ': ' + this.cardHeader
      }
      return '...'
    },

    isChanged () {
      return this.oldCardString !== JSON.stringify(lm.base(this.cardProxy))
    },

    cardId () {
      return this.$route.params.cardId
    },

    listId () {
      return this.$route.params.listId
    },

    disableSave () {
      return this.isLoading || !this.isChanged || this.list.flags?.includes(LIST_FLAGS._NO_CARDS_SAVE)
    },

    disableDelete () {
      return this.isLoading || this.list.flags?.includes(LIST_FLAGS._NO_CARDS_DELETE)
    },

    gradient () {
      return !this.isLoading ? this.$helper.cssBasicHueGradient(this.list.hue) : null
    }
  },

  mounted () {
    this.load()
  },

  watch: {
    '$route' () {
      this.reset()
      this.load()
    }
  },

  methods: {
    setEvaluatorError (functionName) {
      return (error) => {
        this.error = {
          info: functionName + ':' + error.location,
          type: 'error',
          message: error.message
        }
      }
    },

    async persist () {
      await storage.updateCard({
        listId: this.listId,
        newCard: this.cardProxy
      })

      this.setOldCardString(this.cardProxy)
    },

    setOldCardString (card) {
      this.oldCardString = JSON.stringify(lm.base(card))
    },

    async deleteCard () {
      await storage.deleteCard({
        cardId: this.cardId,
        listId: this.listId
      })

      this.$router.back()
    },

    reset () {
      Object.assign(this.$data, INITIAL_DATA())
    },

    computeCardHeader () {
      evaluator(this.list.header, { card: lm.clone(this.cardProxy) })
        .then(header => { this.cardHeader = header.primary })
        .catch(error => { this.cardHeader = error.displayMessage })
    },

    computeFields (payload) {
      const setFields = evaluated => {
        this.fields = Object.keys(evaluated).map(key => {
          return { key, ...evaluated[key] }
        })
      }

      return evaluator(this.list.fields, payload)
        .then(setFields)
        .catch(this.setEvaluatorError('fieldOptions'))
    },

    async load () {
      this.isLoading = true

      const tx = db.transaction(['config', 'cards'])

      this.list = await tx.objectStore('config').get(this.listId)
      const cards = await tx.objectStore('cards').get(this.listId)

      // TODO: what to do with lists as fieldPayload
      const fieldPayload = { lists: [] }
      await this.computeFields(fieldPayload)

      this.setOldCardString(cards[this.cardId])
      this.cardProxy = lm.createProxy(cards[this.cardId])

      this.computeCardHeader()

      this.isLoading = false
    }
  },

  components: {
    Field
  }
}
</script>
