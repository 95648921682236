import lm from 'legible-mergeable'

export const FILE_TYPE = {
  NDJSON: 'ndjson',
  JSON: 'json'
}

const storeFileTypeMap = {
  index: FILE_TYPE.NDJSON,
  config: FILE_TYPE.JSON,
  cards: FILE_TYPE.NDJSON
}

function mergeableToNdjson (mergeable, type) {
  // TODO: somehow detect if the mergeable is sortable. if not sorted the
  //       stringified array elements themselves wouldn't be in the same order
  //       as the metadata definition. the problem currently is that only the
  //       items of the index have a position key, so an error gets thrown.
  const array = (type === 'index')
    ? lm.sorted(mergeable)
    // little hack: transform it to an array with the filter function
    : lm.filter(mergeable)

  array.push(lm.modifications(mergeable))

  return array.map(line => JSON.stringify(line) + '\n').join('')
}

function ndjsonToMergeable (ndjson) {
  const array = ndjson.split('\n')
    .filter(line => line.trim() !== '')
    .map(line => JSON.parse(line))

  const modifications = array.pop()
  const mergeable = lm.fromArray(array, 'id')

  lm.modifications(mergeable, modifications)

  return mergeable
}

export function getFilename (type, listname) {
  return `${listname}.${type}.${storeFileTypeMap[type]}`
}

export function toFile (body, type) {
  const filetype = storeFileTypeMap[type]

  if (filetype === FILE_TYPE.NDJSON) {
    return mergeableToNdjson(body, type)
  }

  if (filetype === FILE_TYPE.JSON) {
    return JSON.stringify(body, null, 2)
  }
}

export function fromFile (body, type) {
  const filetype = storeFileTypeMap[type]

  if (filetype === FILE_TYPE.NDJSON) {
    return ndjsonToMergeable(body)
  }

  if (filetype === FILE_TYPE.JSON) {
    return JSON.parse(body)
  }
}
