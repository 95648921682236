import { fromFile, toFile, getFilename } from './mergeable-file-transformer'

export async function get (type, listname, options) {
  const response = await fetch(getUrl(type, listname, options), {
    headers: getAuthorizationHeaders(options),
    method: 'GET',
    cache: 'no-cache'
  })

  let body = null

  if (response.status === 200) {
    body = fromFile(await response.text(), type)
  }

  return { response, body }
}

export function put (type, listname, body, options) {
  return fetch(getUrl(type, listname, options), {
    body: toFile(body, type),
    headers: getAuthorizationHeaders(options),
    method: 'PUT'
  })
}

export function remove (type, listname, options) {
  return fetch(getUrl(type, listname, options), {
    headers: getAuthorizationHeaders(options),
    method: 'DELETE'
  })
}

function getAuthorizationHeaders (options) {
  if (options.username && options.password) {
    const authString = window.btoa(`${options.username}:${options.password}`)
    return { Authorization: `Basic ${authString}` }
  }

  return {}
}

function getUrl (type, listname, options) {
  const url = getFilename(type, listname)

  if (options.baseUrl && url) {
    return `${options.baseUrl}/${url}`
  } else if (options.baseUrl) {
    return options.baseUrl
  }
}
