<script>
import helper from '@/helper'

const CANCEL_BUTTON_DEFAULT = {
  label: 'Cancel',
  class: null,
  show: true
}

export default {
  name: 'BaseDialog',

  props: {
    cancelOptions: {
      type: Object,
      required: false
    },

    visible: {
      type: Boolean,
      required: true
    },

    initialDialogId: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      dialogId: null
    }
  },

  created () {
    this.dialogId = this.initialDialogId || helper.generateRandomId()
  },

  beforeDestroy () {
    if (this.dialogId) {
      this.$store.commit('dialog/close', { id: this.dialogId })
    }
  },

  watch: {
    visible: {
      immediate: true,

      handler (value) {
        if (!this.dialogId) {
          return
        }

        if (value) {
          this.$store.commit('dialog/show', { id: this.dialogId })
        } else {
          this.$store.commit('dialog/close', { id: this.dialogId })
        }
      }
    }
  },

  computed: {
    cancel () {
      return { ...CANCEL_BUTTON_DEFAULT, ...this.cancelOptions }
    },

    isInForeground () {
      return this.$store.getters['dialog/isInForeground'](this.dialogId)
    },

    level () {
      const level = this.$store.getters['dialog/level'](this.dialogId)

      return 100 + (level * 2)
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template>
  <div class="dialog" :class="{ 'dialog--in-background': !isInForeground }" @keyup.esc="close()">
    <transition appear name="dialog-slide-up">
      <div v-if="visible" class="dialog__content" :style="{ zIndex: level }">
        <slot></slot>

        <div class="button-wrapper">
          <slot name="buttons" />

          <button
            v-show="cancel.show"
            v-text="cancel.label"
            class="button full"
            :class="cancel.class"
            @click="close()"
          />
        </div>
      </div>
    </transition>

    <div v-if="visible" class="dialog__backdrop" :style="{ zIndex: level - 1 }" @click="close"></div>
  </div>
</template>

<style>
.dialog__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.6;
  pointer-events: all;
}

.dialog__content {
  position: fixed;
  left: 50%;
  bottom: max(env(safe-area-inset-bottom), 10px);
  transform: translateX(-50%);
  width: 400px;
  max-width: calc(100% - 20px);
  max-height: calc(100vh - 20px);
  overflow-y: scroll;
  background-color: #262626;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.60);
  transition: transform 100ms cubic-bezier(.3,.6,.4,1);
}

.dialog--in-background .dialog__backdrop {
  display: none;
}

.dialog--in-background .dialog__content {
  transform: translateX(-50%) scale(0.9);
  box-shadow: none;
  background-color: #424242;
}

.dialog-slide-up-enter,
.dialog-slide-up-leave-to {
  transform: translateX(-50%) translateY(calc(100% + env(safe-area-inset-bottom))) !important;
}
</style>
